import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import ApngComponent from "react-apng";
import talkANM from "../assets/animations/Chat.png";
import disappointedANM from "../assets/animations/Disappointed.png";
import happyANM from "../assets/animations/Happy.png";
import listenANM from "../assets/animations/Idle.png";
import laughANM from "../assets/animations/Laugh.png";
import loveANM from "../assets/animations/Love.png";
import sadANM from "../assets/animations/Sad.png";
import shockedANM from "../assets/animations/Shocked.png";
import WhistlingANM from "../assets/animations/Whistling.png";
import _ from "lodash";

const Avatar = ({ mood }) => {
  const moodSet = {
    talk: talkANM,
    disappointed: disappointedANM,
    happy: happyANM,
    listen: listenANM,
    laugh: laughANM,
    love: loveANM,
    sad: sadANM,
    shocked: shockedANM,
    whistling: WhistlingANM,
  };

  const [currMood, setMood] = useState(
    "listen"
    // _.sample(["whistling", "happy", "laugh", "love", "listen"])
  );
  const [lastChanged, setLastChanged] = useState(new Date().getTime());
  const apngControl = useRef();

  function changeAnimation(mood) {
    if (mood !== currMood) {
      setMood(mood);
      setLastChanged(new Date().getTime());
    }
  }

  // timeout to avoid updating animation too fast, causing it to tweak out
  useLayoutEffect(() => {
    const diff = new Date().getTime() - lastChanged;
    if (diff < 500) {
      setTimeout(changeAnimation(mood), 500 - diff);
    } else if (mood !== currMood) changeAnimation(mood);
  }, [mood]);

  return (
    <div>
      <ApngComponent
        ref={apngControl}
        className="avatar-size"
        src={moodSet[currMood]}
        autoPlay={true}
        rate={1}
      />
    </div>
  );
};

export default Avatar;
