import React from "react";
import { IconButton } from "@material-ui/core";
import MicSvg from "../assets/svgs/MicIcon";

export default function StartEndButton({
  handleMicClick,
  buttonText,
  listening,
}) {
  return (
    <div>
      <div className="mic-button" onClick={handleMicClick}>
        <MicSvg isActive={listening} />
      </div>
      <div
        className={
          buttonText == "Start"
            ? "mic-button-text start"
            : "mic-button-text end"
        }
      >
        {buttonText}
      </div>
    </div>
  );
}
