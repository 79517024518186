import React from "react";
import "./TutorialStyle.css";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import stepImg1 from "../../assets/imgs/step_1.png";
import stepImg2 from "../../assets/imgs/step_2.png";
import stepImg3 from "../../assets/imgs/step_3.png";

const onBoardingSteps = [
  {
    title: "Step 1",
    subTitle: "Choose an activity",
    description:
      "Before talking with Skyler, please choose an improv activity that you are interested in and read the rules. Following these rules during the conversation, you will know how to keep Skyler happy.",
  },
  {
    title: "Step 2",
    subTitle: "Review",
    description:
      "After talking with Skyler, you can review your chat recordings. Look at your performances, learn from it and improve your soft skills.",
  },
];

class TutorialPage extends React.Component {
  stepImgs = [stepImg1, stepImg2, stepImg3];

  state = {
    currentId: 0,
    currentStep: 0,
    stepImgSrc: stepImg1,
  };
  constructor(props) {
    super(props);
    this.state = {
      currentId: 0,
      currentStep: 0,
      stepImgSrc: stepImg1,
    };
  }

  goPrevStep() {
    if (this.state.currentStep !== 0) {
      this.setState({
        stepImgSrc: this.stepImgs[this.state.currentStep - 1],
        currentStep: this.state.currentStep - 1,
      });
    }
  }

  goNextStep() {
    // console.log(this.state.currentStep)
    if (this.state.currentStep !== 2) {
      this.setState({
        stepImgSrc: this.stepImgs[this.state.currentStep + 1],
        currentStep: this.state.currentStep + 1,
      });
    }
  }

  render() {
    return (
      <div
        className={
          !this.state.currentStep
            ? "tutorial full-screen-container"
            : "tutorial full-screen-container-two"
        }
      >
        <div className="inner-full-screen-container">
          <div className="container">
            <Grid container spacing={0} className="content">
              <Grid item xs={6} className="left-box">
                <img
                  className="step-img"
                  style={{ opacity: this.state.currentStep === 0 ? 1 : 0 }}
                  src={stepImg2}
                  alt="step 1"
                ></img>
                <img
                  className="step-img"
                  style={{ opacity: this.state.currentStep === 1 ? 1 : 0 }}
                  src={stepImg3}
                  alt="step 2"
                ></img>
                <img
                  className="step-img"
                  style={{ opacity: this.state.currentStep === 2 ? 1 : 0 }}
                  src={stepImg3}
                  alt="step 3"
                ></img>
              </Grid>
              <Grid item xs={6} className="right-box">
                <Typography
                  variant="h3"
                  component="h3"
                  gutterBottom
                  className="short-paragraphy text-weight-heavy text-white"
                >
                  {onBoardingSteps[this.state.currentStep].title}
                </Typography>
                <Typography
                  variant="h5"
                  component="h5"
                  gutterBottom
                  className="short-paragraphy text-weight-heavy text-white"
                >
                  {onBoardingSteps[this.state.currentStep].subTitle}
                </Typography>
                <Typography
                  variant="body1"
                  component="p"
                  gutterBottom
                  className="short-paragraphy text-white"
                >
                  {onBoardingSteps[this.state.currentStep].description}
                </Typography>
                <div className="stepper-button-group">
                  {this.state.currentStep ? (
                    <IconButton
                      className="text-white"
                      onClick={() => this.goPrevStep()}
                    >
                      <ArrowBackIosIcon />
                    </IconButton>
                  ) : (
                    <IconButton
                      className="text-white"
                      onClick={() => this.goNextStep()}
                    >
                      <ArrowForwardIosIcon />
                    </IconButton>
                  )}
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}

export default TutorialPage;
