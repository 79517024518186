import React, { useState } from "react";
import "./ChatPageStyle.css";
import Dictaphone from "../../components/Dictaphone";
import TextBox from "../../components/TextBox";
import Conversation from "../../components/ConversationLog";
import { Grid } from "@material-ui/core";
import { sendStatement } from "../../services/modelService";
import { ExportConversation } from "../../components/ExportConversation";
import Typography from "@material-ui/core/Typography";
import Alert from '@material-ui/lab/Alert';
import Avatar from "../../components/Avatar";

function AdminChatPage() {
  const [conversation, setConversation] = useState([]);
  const [alerts, setAlerts] = useState([]);

  const alertCallback = (response, action) => {
    if(response.success){
      const alert = {
        id: Date.now(),
        severity: "success",
        text: "Success: " + action
      };

      setAlerts([...alerts, alert]);
      setTimeout( () => {
        setAlerts(alerts => alerts.filter(a => (a.id !== alert.id)))
      }        
      , 5000);
    }

    else{
      const alert = {
        id: Date.now(),
        severity: "error",
        text: "Failed to " + action
      }

      setAlerts([...alerts, alert]);
      setTimeout( () => {
        setAlerts(alerts => alerts.filter(a => (a.id !== alert.id)))
      }        
      , 5000);
    }
  }

  const addToDialogue = (convo) => {
    setConversation([...convo]);
  };

  async function exchangeDialogue(words, audioBlob) {
    conversation.push({
      role: "human",
      words: words,
      audioBlob: audioBlob,
    });
    addToDialogue(conversation);
    const response = await sendStatement(words);
    conversation.push({ role: "ai", words: response });
    addToDialogue(conversation);
  }

  function exchangeTurns(flag) {}
  function setTalking(flag) {}

  return (
    <div className="chat full-screen-container">
      <Grid container spacing={0} className="content">
        <Grid item xs={5} className="left-box">
        <Typography
            variant="h4"
            component="h4"
            className="admin-title"
          >
            Chatbot Training
      </Typography>
          <TextBox exchangeDialogue={exchangeDialogue} />
          <Dictaphone
            conversation={conversation}
            exchangeDialogue={exchangeDialogue}
            exchangeTurns={exchangeTurns}
            setTalking={setTalking}
            showAdminButtons={true}
            autoListen={false}
            dictaphoneOn={true}
          />
        </Grid>
        <Grid item xs={7} className="right-box">          
          <Conversation adminCallback = {alertCallback} conversation={conversation} admin />
          {alerts.map(alert =>  {
            return <Alert severity={alert.severity}>{alert.text}</Alert>
          })}
        </Grid>
        <ExportConversation conversation={conversation} />
      </Grid>
    </div>
  );
}

export default AdminChatPage;
