import { IconButton, Button } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import React from "react";
import { useHistory } from "react-router-dom";

var FileSaver = require("file-saver");

const readUploadedFileAsBase64 = (inputFile) => {
  const temporaryFileReader = new FileReader();

  return new Promise((resolve, reject) => {
    temporaryFileReader.onerror = () => {
      temporaryFileReader.abort();
      reject(new DOMException("Problem parsing input file."));
    };

    temporaryFileReader.onload = () => {
      resolve(temporaryFileReader.result);
    };
    temporaryFileReader.readAsDataURL(inputFile);
  });
};

export function ExportConversation({ conversation, mode, setDownloading }) {
  let history = useHistory();

  const saveFile = async () => {
    let convoToText = [...conversation];
    convoToText = await Promise.all(
      convoToText.map(async (line) => {
        if (line.role === "human") {
          const base64Data = await readUploadedFileAsBase64(line.audioBlob);
          return { role: line.role, words: line.words, audioBlob: base64Data };
        } else {
          return line;
        }
      })
    );
    convoToText = JSON.stringify(convoToText);

    var blob = new Blob([convoToText], {
      type: "text/plain;charset=utf-8",
    });
    FileSaver.saveAs(blob, "ImprovBot_" + Date.now().toString());
  };

  const openNow = () => {
    conversation.forEach((line) => {
      if (line.role === "human")
        line.audioBlob = URL.createObjectURL(line.audioBlob);
    });

    history.push({
      pathname: "/chat-playback",
      loadConversation: conversation,
    });
  };

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {mode === "interview" ? (
          <Button
            className="outlined btn-round download-outlined"
            onClick={() => setDownloading(true)}
            style={{
              marginTop: "1em",
              backgroundColor: "#FFFFFF",
              color: "#FF9288",
              textTransform: "none",
              maxWidth: "352px",
              fontSize: "1.3em",
            }}
          >
            <b>Download Recording</b>
          </Button>
        ) : (
          <div>
            <Button
              className="outlined btn-round download-outlined"
              onClick={saveFile}
              style={{
                marginTop: "1em",
                backgroundColor: "#FFFFFF",
                color: "#FF9288",
                textTransform: "none",
                maxWidth: "352px",
                fontSize: "1.3em",
              }}
            >
              <b>Download Transcription</b>
            </Button>
            <Button
              className="outlined btn-round download-outlined"
              onClick={() => openNow()}
              style={{
                marginTop: "1em",
                backgroundColor: "#FFFFFF",
                color: "#FF9288",
                textTransform: "none",
                minWidth: "400px",
                maxWidth: "400px",
                fontSize: "1.3em",
              }}
            >
              <b>Analyze my Transcription</b>
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
