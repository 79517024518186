import http from "./httpService";

const chatbotPath = "/say-again";
const apiEndpoint = window.apiUrl + chatbotPath;

export async function getPrompt(text, convoId) {
  const response = await http.post(apiEndpoint + "/get-prompt", {
    text: text,
    convoId: convoId,
  });
  if (response.data) return response.data;
  else
    return {
      reply: "Error, please refresh the page.",
      feedback: "Refresh the page",
    };
}

export async function initiateGame(text) {
  const response = await http.get(apiEndpoint + "/initiate-game", {
    text: text,
  });
  if (response.data) return response.data;
  else
    return {
      reply: "Error, please refresh the page.",
      feedback: "Refresh the page",
    };
}

export async function getFeedback(answers) {
  const response = await http.post(apiEndpoint + "/get-feedback", {
    answers: answers,
  });
  if (response.data) return response.data;
  else
    return {
      reply: "Error, please refresh the page.",
      feedback: "Refresh the page",
    };
}
