import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import "./HeaderStyle.css";
import "../common.css";
import BrandIcon from "../../assets/imgs/ib_logo.svg";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from "@material-ui/core/Button";
import "./HeaderStyle.css";
import { Link } from "react-router-dom";
import FileSaver from "file-saver";
import { useHistory } from "react-router-dom";

export default function Header(props) {
  let history = useHistory();

  return (
    <AppBar className="appBar transparent">
      <Toolbar className="navContainer">
        <div className="clickable">
          <img className="navBrand" src={BrandIcon} alt="brand icon" />
          <div
            className="home-button"
            onClick={() => {
              history.push("/");
            }}
          >
            <span className="brandTitle text-white">ImprovBot</span>
          </div>
        </div>
        <List className="navList">
          <ListItem className="navListItem">
            <Button className="navLink">
              <div
                onClick={() => {
                  FileSaver.saveAs(
                    process.env.PUBLIC_URL +
                      "/resource/Zoom_Handbook_18Nov2020.pdf",
                    "Improvbot_Zoom_Handbook_18Nov2020.pdf"
                  );
                }}
                className="inline-blank-tab-link text-white"
              >
                Handbook
              </div>
            </Button>
            <Button className="navLink">
              <Link
                to="/chat-playback"
                className="inline-blank-tab-link text-white"
                replace
              >
                Playback
              </Link>
            </Button>
            <Button className="navLink">
              <Link to="/" className="inline-blank-tab-link text-white">
                About Us
              </Link>
            </Button>
            <Button className="navLink primary-button">
              <Link
                to="/activity"
                className="inline-blank-tab-link text-white primary-button"
              >
                Get Started
              </Link>
            </Button>
          </ListItem>
        </List>
      </Toolbar>
    </AppBar>
  );
}
