import React from "react";

const EndButton = ({ onClick }) => {
  return (
    <svg
      width="186"
      height="84"
      viewBox="0 0 186 84"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className="footer-button"
    >
      <path d="M85.0861 68.038V70H75.6361V57.4H84.8341V59.362H77.9761V62.638H84.0601V64.564H77.9761V68.038H85.0861ZM93.5755 60.28C94.7875 60.28 95.7595 60.634 96.4915 61.342C97.2235 62.05 97.5895 63.1 97.5895 64.492V70H95.3395V64.78C95.3395 63.94 95.1415 63.31 94.7455 62.89C94.3495 62.458 93.7855 62.242 93.0535 62.242C92.2255 62.242 91.5715 62.494 91.0915 62.998C90.6115 63.49 90.3715 64.204 90.3715 65.14V70H88.1215V60.388H90.2635V61.63C90.6355 61.186 91.1035 60.85 91.6675 60.622C92.2315 60.394 92.8675 60.28 93.5755 60.28ZM110.589 56.644V70H108.429V68.758C108.057 69.214 107.595 69.556 107.043 69.784C106.503 70.012 105.903 70.126 105.243 70.126C104.319 70.126 103.485 69.922 102.741 69.514C102.009 69.106 101.433 68.53 101.013 67.786C100.593 67.03 100.383 66.166 100.383 65.194C100.383 64.222 100.593 63.364 101.013 62.62C101.433 61.876 102.009 61.3 102.741 60.892C103.485 60.484 104.319 60.28 105.243 60.28C105.879 60.28 106.461 60.388 106.989 60.604C107.517 60.82 107.967 61.144 108.339 61.576V56.644H110.589ZM105.513 68.2C106.053 68.2 106.539 68.08 106.971 67.84C107.403 67.588 107.745 67.234 107.997 66.778C108.249 66.322 108.375 65.794 108.375 65.194C108.375 64.594 108.249 64.066 107.997 63.61C107.745 63.154 107.403 62.806 106.971 62.566C106.539 62.314 106.053 62.188 105.513 62.188C104.973 62.188 104.487 62.314 104.055 62.566C103.623 62.806 103.281 63.154 103.029 63.61C102.777 64.066 102.651 64.594 102.651 65.194C102.651 65.794 102.777 66.322 103.029 66.778C103.281 67.234 103.623 67.588 104.055 67.84C104.487 68.08 104.973 68.2 105.513 68.2Z" />
      <path d="M92.6164 10.0301C83.4373 10.0378 74.9989 17.6865 74.1329 26.8359C73.2036 34.6286 77.5892 42.7736 84.7471 46.0836C91.3944 49.3325 99.9265 48.3044 105.44 43.3018C111.049 38.4478 113.5 30.1741 111.036 23.1156C108.693 15.9142 101.778 10.4011 94.1614 10.0674C93.6471 10.0338 93.1316 10.0275 92.6164 10.0301ZM92.9924 15.9718C99.7523 15.7752 105.95 21.7373 106.029 28.5C106.321 34.6867 101.674 40.7618 95.5082 41.799C89.5422 42.9941 82.9254 39.5462 80.8371 33.7542C78.589 28.0265 80.8826 20.7834 86.3546 17.7772C88.3554 16.6025 90.6717 15.969 92.9924 15.9718ZM92.9998 19.3648C86.7515 19.1276 81.7294 25.954 83.795 31.8474C85.416 37.8828 93.4186 40.674 98.4403 36.9523C103.729 33.62 104.029 25.1506 98.9892 21.4525C97.3034 20.1077 95.156 19.3595 92.9998 19.3648V19.3648Z" />
    </svg>
  );
};

export default EndButton;
