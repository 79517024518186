import http from "./httpService";

const chatbotPath = "/chatbot";
const apiEndpoint = window.apiUrl + chatbotPath;

export async function sendYesAndStatement(text, convoId) {
  const response = await http.post(apiEndpoint + "/yes-and", {
    text: text,
    convoId: convoId,
  });
  if (response.data) return response.data;
  else
    return {
      reply: "Error, please refresh the page.",
      feedback: "Refresh the page",
    };
}

export async function initiateGame(text) {
  const response = await http.get(apiEndpoint + "/initiate-game", {
    text: text,
  });
  if (response.data) return response.data;
  else
    return {
      reply: "Error, please refresh the page.",
      feedback: "Refresh the page",
    };
}

export async function sendFreeTalk(text, convoId) {
  const response = await http.post(apiEndpoint + "/send", {
    text: text,
    id: convoId,
  });
  if (response.data.response) {
    console.log(response);
    return response.data.response;
  } else
    return {
      reply: "Error, please refresh the page.",
      feedback: "Refresh the page",
    };
}

export async function initiateFreeTalk() {
  const response = await http.get(apiEndpoint + "/initiate-ft");
  if (response.data) return response.data;
  else
    return {
      reply: "Error, please refresh the page.",
      feedback: "Refresh the page",
    };
}

export async function getInterviewQuestion() {
  const response = await http.get(apiEndpoint + "/interview-question");
  if (response.data) return response.data;
  else
    return {
      reply: "Error, please refresh the page.",
      feedback: "Refresh the page",
    };
}
