import React, { useState } from "react";
import { Button, Input, Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {
  addBotResponse,
  editBotResponse,
  removeBotResponse,
} from "../services/modelService";

export default function Conversation({
  conversation,
  admin,
  interimTranscript,
  isPlayerTurn,
  adminCallback,
}) {
  // Index to display button options
  const [displayIndex, setDisplayIndex] = useState();
  // Based on users desired action = "add", "edit", "remove", or null
  const [currentInput, setCurrentInput] = useState();
  // Text inside the input
  const [focusText, setFocusText] = useState();
  // Which confirmation to display = "remove" or "edit"
  const [displayConfirmation, setDisplayConfirmation] = useState();

  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip);

  const onBubbleClick = (text, index) => {
    setDisplayIndex(index);
    setFocusText(text);
  };

  const resetDisplay = () => {
    setCurrentInput(null);
    setDisplayIndex(null);
    setDisplayConfirmation(null);
  };

  const setInput = (action) => {
    if (action === "add" || action === "edit") setCurrentInput(action);
    if (action === "add") setFocusText("");
    if (action === "remove") setDisplayConfirmation("remove");
  };

  const addResponse = async () => {
    const result = await addBotResponse(
      focusText,
      conversation[displayIndex].words
    );
    adminCallback(result, "add response: " + "'" + focusText + "'");
    console.log(result);
    resetDisplay();
  };

  const removeResponse = async () => {
    const result = await removeBotResponse(conversation[displayIndex].words);
    adminCallback(
      result,
      "remove response: " + "'" + conversation[displayIndex].words + "'"
    );
    console.log(result);
    resetDisplay();
  };

  const editResponse = async () => {
    const result = await editBotResponse(
      conversation[displayIndex].words,
      focusText
    );
    adminCallback(result, "edit response to: " + "'" + focusText + "'");
    console.log(result);
    resetDisplay();
  };

  const onFormSubmit = (e, action, text) => {
    e.preventDefault();
    if (action === "add") addResponse(text);
    else if (action === "remove") removeResponse();
    else if (action === "edit") editResponse();
  };

  const aiButtons = (
    <div>
      <LightTooltip
        title="Add a new Chatbot response for this message"
        placement="left"
      >
        <Button onClick={() => setInput("add")}>Add Response</Button>
      </LightTooltip>
      <LightTooltip title="Remove this from Chatbot's list of responses">
        <Button onClick={() => setInput("remove")}>Remove Response</Button>
      </LightTooltip>
      <LightTooltip title="Edit this Chatbot response">
        <Button onClick={() => setInput("edit")}>Edit Response</Button>
      </LightTooltip>
      <Button onClick={() => resetDisplay()}>Cancel</Button>
    </div>
  );

  const humanButtons = (
    <div>
      <LightTooltip
        title="Add a new Chatbot response for this message"
        placement="left"
      >
        <Button onClick={() => setInput("add")}>Add Response</Button>
      </LightTooltip>
      <Button onClick={() => resetDisplay()}>Cancel</Button>
    </div>
  );

  const inputForm = (
    <form onSubmit={(e) => onFormSubmit(e, currentInput, focusText)}>
      <Input
        value={focusText}
        onChange={(e) => setFocusText(e.target.value)}
        onBlur={() => resetDisplay()}
        className="text-input"
        type="text"
        autoFocus
        placeholder="Type and Enter"
      />
    </form>
  );

  const removeConfirm = (
    <div>
      <span>
        {"Are you sure you want to remove '" +
          focusText +
          "' from the database?"}
      </span>
      <Button onClick={() => removeResponse()}>Remove</Button>
      <Button onClick={() => resetDisplay()}>Cancel</Button>
    </div>
  );

  const editConfirm = (
    <div>
      <span>
        {"Are you sure you want to change this statement to '" +
          focusText +
          "' ?"}
      </span>
      <Button onClick={() => editResponse()}>Remove</Button>
      <Button onClick={() => resetDisplay()}>Cancel</Button>
    </div>
  );

  const ChatBubbleAdmin = (text, i, className) => {
    return (
      <div
        key={`${className}-${i}`}
        className={`${className} chat-bubble`}
        onClick={() => {
          !displayIndex && onBubbleClick(text, i);
        }}
      >
        <span className="chat-content">
          {text}

          {/* Conditionals to display button options or input text */}
          {displayIndex === i &&
            (displayConfirmation
              ? (displayConfirmation === "edit" && editConfirm) ||
                (displayConfirmation === "remove" && removeConfirm)
              : currentInput
              ? inputForm
              : className === "ai"
              ? aiButtons
              : className === "human"
              ? humanButtons
              : null)}
        </span>
      </div>
    );
  };

  const ChatBubbleUser = (text, i, role, hidden) => {
    return (
      <div key={`${role}-${i}`} className={`${role} chat-bubble`}>
        <span className="chat-content">
          {hidden ? "@#$%%##@@$$###$#@@" : text}
        </span>
      </div>
    );
  };

  return (
    <div className="conversation-view">
      <div className="content">
        <div>
          {conversation.map((line, index) => {
            if (admin)
              return (
                <div key={index}>
                  {ChatBubbleAdmin(line.words, index, line.role)}
                </div>
              );
            else
              return (
                <div key={index}>
                  {ChatBubbleUser(line.words, index, line.role, line.hidden)}
                </div>
              );
          })}
          {interimTranscript.length && isPlayerTurn
            ? ChatBubbleUser(interimTranscript, "interim", "human")
            : null}
        </div>
      </div>
    </div>
  );
}
