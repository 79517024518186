import React, { useState, useEffect } from "react";
import Conversation from "../components/ConversationLog";
import Dictaphone from "../components/Dictaphone";
import Avatar from "../components/Avatar";
import { sendStatement } from "../services/modelService";
import { sendFreeTalk, initiateFreeTalk } from "../services/chatbotService";
import StartEndButton from "../components/StartEndButton";

function ChatPage() {
  const [conversation, setConversation] = useState([]);
  const [animation, setAnimation] = useState("listen");
  const [interimTranscript, setInterimState] = useState("");
  const [convoId, setId] = useState(false);

  const [isStarted, setStartGame] = useState(false);
  const [isEnded, setEndGame] = useState(false);
  const [listening, setListening] = useState(false);
  const [speaking, setSpeaking] = useState();
  const [currentDialogue, setCurrentDialogue] = useState();

  const isMounted = React.useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (speaking) setAnimation("talk");
    else setAnimation("listen");
  }, [speaking]);

  const addToConversation = (convo) => {
    if (isMounted.current) setConversation([...convo]);
  };

  async function onBotSpeakingEnd() {
    setCurrentDialogue(false);
    setInterimState("");
    setListening(true);
  }

  function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async function exchangeDialogue(words, audioBlob) {
    conversation.push({
      role: "human",
      words: words,
      audioBlob: audioBlob,
    });
    addToConversation(conversation);

    setInterimState("");
    const response = await sendFreeTalk(words, convoId);
    setCurrentDialogue(response);
    conversation.push({ role: "ai", words: response });
    addToConversation(conversation);
  }

  // initiate new game session
  async function initiate() {
    const initiatedGame = await initiateFreeTalk();
    setId(initiatedGame.convoId);
    console.log(initiatedGame);
    setStartGame(true);
    // wait for animation to complete
    await timeout(2000);
    setCurrentDialogue(initiatedGame.response);
  }

  const restartTheGame = () => {
    setListening(false);
    setConversation([]);
    setStartGame(false);
    setEndGame(false);
    setAnimation("listen");
  };

  function handleMicClick() {
    if (!isStarted) {
      initiate();
    } else {
      restartTheGame();
    }
  }

  return (
    <div className="chat full-screen-container">
      <div
        className={
          !isStarted ? "avatar-container-before" : "avatar-container-after"
        }
      >
        <Avatar className="avatar-image" mood={animation} />
        {!isStarted ? (
          <div className="mic-container-before">
            <StartEndButton
              handleMicClick={handleMicClick}
              buttonText={"Start"}
            />
          </div>
        ) : null}
      </div>

      <div className="conversation-container">
        <Conversation
          conversation={conversation}
          interimTranscript={interimTranscript}
          isPlayerTurn={listening}
        />
      </div>

      {isStarted ? (
        <div className="mic-container-after">
          <Dictaphone
            listening={listening}
            setListening={setListening}
            speaking={speaking}
            setSpeaking={setSpeaking}
            setInterimState={setInterimState}
            currentDialogue={currentDialogue}
            exchangeDialogue={exchangeDialogue}
            onEnd={onBotSpeakingEnd}
            handleMicClick={handleMicClick}
            showLevels={isStarted}
            record
          />
        </div>
      ) : null}
    </div>
  );
}

export default ChatPage;
