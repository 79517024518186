import React from "react";

const MicIndicator = ({ listening }) => {
  if (!listening)
    return (
      <svg
        width="42"
        height="42"
        viewBox="0 0 42 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="21" cy="21" r="21" fill="#666666" />
        <path
          d="M28.2685 17.8051C28.2685 16.5868 30.131 16.5868 30.131 17.8051V19.2108C30.131 24.0839 26.4992 28.1136 21.75 28.5822V31.9559C21.75 33.1742 19.8876 33.1742 19.8876 31.9559V28.5822C15.1384 28.1136 11.4136 24.0839 11.4136 19.2108V17.8051C11.4136 16.5868 13.276 16.5868 13.276 17.8051V19.2108C13.276 23.3342 16.6284 26.8016 20.8188 26.8016C24.9162 26.8016 28.2685 23.3342 28.2685 19.2108V17.8051Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.9998 9.58691C18.7587 9.58691 16.8911 11.413 16.8911 13.6043V19.2652C16.8911 21.4565 18.7587 23.2826 20.9998 23.2826C23.2409 23.2826 25.1085 21.4565 25.1085 19.2652V13.6043C25.1085 11.413 23.2409 9.58691 20.9998 9.58691Z"
          fill="white"
        />
      </svg>
    );
  else
    return (
      <svg
        width="42"
        height="42"
        viewBox="0 0 42 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="21" cy="21" r="21" fill="url(#paint0_linear)" />
        <path
          d="M28.2685 17.8053C28.2685 16.587 30.131 16.587 30.131 17.8053V19.211C30.131 24.0842 26.4992 28.1139 21.75 28.5824V31.9562C21.75 33.1744 19.8876 33.1744 19.8876 31.9562V28.5824C15.1384 28.1139 11.4136 24.0842 11.4136 19.211V17.8053C11.4136 16.587 13.276 16.587 13.276 17.8053V19.211C13.276 23.3344 16.6284 26.8019 20.8188 26.8019C24.9162 26.8019 28.2685 23.3344 28.2685 19.211V17.8053Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M20.9998 9.58691C18.7587 9.58691 16.8911 11.413 16.8911 13.6043V19.2652C16.8911 21.4565 18.7587 23.2826 20.9998 23.2826C23.2409 23.2826 25.1085 21.4565 25.1085 19.2652V13.6043C25.1085 11.413 23.2409 9.58691 20.9998 9.58691Z"
          fill="white"
        />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="0"
            y1="0"
            x2="0"
            y2="42"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FFBF9A" />
            <stop offset="1" stop-color="#FF8787" />
          </linearGradient>
        </defs>
      </svg>
    );
};

export default MicIndicator;
