import React from "react";

const SectionButtons = ({ text, onClick, selected }) => {
  return selected ? (
    <div className="section-button-container" onClick={onClick}>
      <div className="section-button-text selected">{text}</div>
      <div className="section-highlighter">
        <svg
          width="186"
          height="44"
          viewBox="0 0 186 44"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="186" height="44" fill="#FFF1EF" />
          <rect width="7" height="44" fill="url(#paint0_linear)" />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="0"
              y1="0"
              x2="0"
              y2="44"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FFBF9A" />
              <stop offset="1" stopColor="#FF8787" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </div>
  ) : (
    <div className="section-button-container" onClick={onClick}>
      <div className="section-button-text">{text}</div>
      <svg
        width="186"
        height="44"
        viewBox="0 0 186 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {/* <rect width="186" height="44" fill="#FFF1EF" /> */}
      </svg>
    </div>
  );
};

export default SectionButtons;
