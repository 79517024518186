import React, { useEffect, useState, useRef } from "react";
import SpeechRecognition from "../utils/SpeechRecognition.jsx";
import SpeechSynthesis from "../utils/SpeechSynthesis";
import { AudioRecorder } from "../utils/AudioRecorder";
import StartEndButton from "./StartEndButton";
import AudioLevelDisplay from "./AudioLevelDisplay";

// let's hear that beautiful voice
let synthesis = new SpeechSynthesis();
synthesis.load("Google US English");

const Dictaphone = (props) => {
  const [finalTranscript, setFinalTranscript] = useState("");
  const [recorder, setRecorder] = useState();
  const [level, setLevel] = useState(0);
  const isMounted = useRef(true);

  const {
    listening,
    setListening,
    speaking,
    setSpeaking,
    setInterimState,
    currentDialogue,
    exchangeDialogue,
    onEnd,
    onAudioEnd,
    playbackMode,
    handleMicClick,
    showLevels,
    manualListen,
    invisible,
    shouldCancel,
    voice,
  } = props;

  useEffect(() => {
    synthesis.changeVoice(voice);
  }, [voice]);

  const playSpeech = (text) => {
    if (!speaking) {
      setSpeaking(true);
      synthesis.speak(text);
    }
  };

  // componentDidMount/componentWillUnmount...
  useEffect(() => {
    const getRecorder = async () => {
      const rec = await AudioRecorder({ setLevel });
      setRecorder(rec);
    };
    getRecorder();
    isMounted.current = true;

    // MUST specify onEnd function for when bot stops speaking
    if (onEnd)
      synthesis.onend(() => {
        if (isMounted.current) {
          setSpeaking(false);
          onEnd();
        }
      });

    // close all streams... still shows recording symbol on browser tab afterwards; research suggests Chrome bug
    return function () {
      navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
        stream.getTracks().forEach((track) => track.stop());
      });
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (shouldCancel) {
      synthesis.cancel();
    }
  }, [shouldCancel]);

  const setTranscript = (value) => {
    if (isMounted.current) setFinalTranscript(value);
  };

  const setInterimTranscript = (value) => {
    if (setInterimState) {
      if (isMounted.current && listening) {
        setInterimState(value);
      } else {
        setInterimState("");
      }
    }
  };

  // listening hook
  useEffect(() => {
    async function listenStateChange() {
      if (recorder)
        if (listening) {
          recorder.start();
          console.log("Listening...");
        } else {
          const done = await recorder.stop();
          if (finalTranscript && finalTranscript.length) {
            const audio = recorder.exportAudio();
            const audioBlob = audio.audioBlob;
            exchangeDialogue(finalTranscript, audioBlob);
            setTranscript("");
          } else if (manualListen) {
            exchangeDialogue("");
          }
          console.log("Stopped listening.");
        }
    }
    if (isMounted.current) listenStateChange();
  }, [listening]);

  // stop listening and exchange dialogue when user stops speaking
  useEffect(() => {
    if (!manualListen)
      if (finalTranscript && finalTranscript.length) {
        setListening(false);
      }
  }, [finalTranscript]);

  useEffect(() => {
    if (currentDialogue) {
      if (!playbackMode) {
        playSpeech(currentDialogue);
      } else if (playbackMode && currentDialogue.audioBlob) {
        const audio = new Audio(currentDialogue.audioBlob);
        audio.onended = onAudioEnd;
        audio.play();
      }
    }
  }, [currentDialogue]);

  // Speech Recognition and button options
  return (
    <div>
      <SpeechRecognition
        autoStart={false}
        continuous={false}
        setInterimTranscript={setInterimTranscript}
        setFinalTranscript={setTranscript}
        finalTranscript={finalTranscript}
        listening={listening}
      />
      <div>
        {showLevels ? (
          <div className="levels-container">
            <AudioLevelDisplay
              listening={listening}
              microphone={recorder}
              level={level}
            />
          </div>
        ) : null}
        {!invisible ? (
          <div className="start-end-button-container">
            <StartEndButton
              handleMicClick={handleMicClick}
              buttonText={"End"}
              isActive={listening}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Dictaphone;
