// Commented-out assets may be used later
// import card_01 from "../assets/imgs/cards/card-01.png";
import card_02 from "../assets/imgs/cards/card-02.png";
import card_03 from "../assets/imgs/cards/card-03.png";
import card_04 from "../assets/imgs/cards/card-04.png";
import card_07 from "../assets/imgs/cards/card-07.png";
import card_06 from "../assets/imgs/cards/card-06.png";
// import stepImg1 from "../assets/imgs/step_1.png";
import stepImg2 from "../assets/imgs/step_2.png";
import stepImg3 from "../assets/imgs/step_3.png";

export const ActivityData = [
  // {
  //   name: "Story Swappong",
  //   id: 0,
  //   img: card_01,
  // },
  {
    name: "Free talk",
    id: 1,
    img: card_02,
  },

  {
    name: "Repeat",
    id: 2,
    img: card_04,
  },
  {
    name: "Interview",
    id: 4,
    img: card_07,
  },
  {
    name: "Yes...",
    id: 3,
    img: card_03,
  },

  {
    name: "Say Again",
    id: 5,
    img: card_06,
  },
];

export const tutorialSteps = [
  {
    title: "Step 1",
    subTitle: "Choose an activity",
    description:
      "Before talking with Skyler, please choose an improv activity that you are interested in and read the rules. Following these rules during the conversation, you will know how to keep Skyler happy.",
    image: stepImg2,
  },
  {
    title: "Step 2",
    subTitle: "Review",
    description:
      "After talking with Skyler, you can review your chat recordings. Look at your performances, learn from it and improve your soft skills.",
    image: stepImg3,
  },
];
