import React, { useState, useEffect, useRef } from "react";
import { Collapse } from "@material-ui/core";
import ResultDialog from "../components/ResultDialog";
import RulesDialog from "../components/RulesDialog";
import Dictaphone from "../components/Dictaphone";
import Avatar from "../components/Avatar";
import Timer from "../components/Timer";
import StartEndButton from "../components/StartEndButton";
import { sendYesAndStatement, initiateGame } from "../services/chatbotService";
import Conversation from "../components/ConversationLog";
import { Star } from "@material-ui/icons";

const timeLimit = 60;

function YesAndPage() {
  const [conversation, setConversation] = useState([]);
  const [interimTranscript, setInterimState] = useState("");
  const [score, setScore] = useState(100);
  const [convoId, setId] = useState(false);

  const [isStarted, setStartGame] = useState(false);
  const [isEnded, setEndGame] = useState(false);

  const [animation, setAnimation] = useState("listen");

  const [gameResult, setGameResult] = useState([]);

  const [currentDialogue, setCurrentDialogue] = useState();
  const [dialogueQueue, setDialogueQueue] = useState([]);
  const [listening, setListening] = useState(false);
  const [speaking, setSpeaking] = useState();

  // set the next dialogue to be spoken from the dialogue queue
  useEffect(() => {
    async function shiftDialogueQueue(nextLine) {
      await timeout(500);
      setCurrentDialogue(nextLine.words);
      setDialogueQueue([...dialogueQueue]);
      addToConversation({
        role: "ai",
        words: nextLine.words,
        hidden: nextLine.hidden,
      });
    }
    if (dialogueQueue.length && !currentDialogue && !speaking) {
      const nextLine = dialogueQueue.shift();
      shiftDialogueQueue(nextLine);
    }
  });

  useEffect(() => {
    if (speaking) setAnimation("talk");
    else setAnimation("listen");
  }, [speaking]);

  useEffect(() => {
    if (isEnded) {
      setListening(false);
      setGameResult([`FINAL SCORE: ${score}`]);
    }
  }, [isEnded, score]);

  function addToDialogueQueue(statement) {
    dialogueQueue.push(statement);
    setDialogueQueue(dialogueQueue);
  }

  function addToConversation(statement) {
    conversation.push(statement);
    setConversation([...conversation]);
  }

  function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async function onBotSpeakingEnd() {
    const nextDialogue = dialogueQueue.length;
    if (nextDialogue) {
      setCurrentDialogue(false);
    } else {
      setInterimState("");
      if (!dialogueQueue.length) setListening(true);
    }
  }

  async function exchangeDialogue(words, audioBlob) {
    addToConversation({
      role: "human",
      words: words,
      audioBlob: audioBlob,
      hidden: false,
    });
    setInterimState("");

    const response = await sendYesAndStatement(words, convoId);
    console.log(response.feedback);
    if (response.feedback.length)
      response.feedback.forEach((feedback) =>
        addToDialogueQueue({ words: feedback })
      );
    setScore(response.score);

    addToDialogueQueue({ role: "ai", words: response.reply });
    setCurrentDialogue(false);
  }

  async function initiate() {
    setStartGame(true);
    // wait for animation to complete
    await timeout(2000);
    const gameData = await initiateGame();
    addToDialogueQueue({
      words:
        "Remember, always begin with 'yes and', and reuse nouns throughout the conversation!",
    });
    addToDialogueQueue({
      words: `Begin a story using these two nouns.  "${gameData.nounSuggestions[0]}" and "${gameData.nounSuggestions[1]}"`,
    });
    setId(gameData.convoId);
  }

  const restartTheGame = () => {
    setListening(false);
    setConversation([]);
    setCurrentDialogue(false);
    setScore(100);
    setId(false);
    setStartGame(false);
    setGameResult([]);
    setEndGame(false);
    setAnimation("listen");
  };

  function handleMicClick() {
    if (!isStarted) {
      initiate();
    } else {
      restartTheGame();
    }
  }

  return (
    <div className="yesand full-screen-container">
      <div
        className={
          !isStarted ? "avatar-container-before" : "avatar-container-after"
        }
      >
        <Avatar className="avatar-image" mood={animation} />
        {!isStarted ? (
          <div className="mic-container-before">
            <StartEndButton
              handleMicClick={handleMicClick}
              buttonText={"Start"}
            />
          </div>
        ) : null}
      </div>

      <div className="conversation-container">
        <Conversation
          conversation={conversation}
          interimTranscript={interimTranscript}
          isPlayerTurn={listening}
        />
      </div>

      {isStarted ? (
        <div className="mic-container-after">
          <Dictaphone
            listening={listening}
            setListening={setListening}
            speaking={speaking}
            setSpeaking={setSpeaking}
            setInterimState={setInterimState}
            currentDialogue={currentDialogue}
            exchangeDialogue={exchangeDialogue}
            onEnd={onBotSpeakingEnd}
            handleMicClick={handleMicClick}
            showLevels={isStarted}
            record
          />
        </div>
      ) : null}

      <ResultDialog
        title="Your time is up!"
        contentArray={gameResult}
        open={isEnded}
        closeFunction={restartTheGame}
        conversation={conversation}
        maxWidth="xs"
      />
      {isStarted ? (
        <div className="timer-container">
          <Timer
            timeLimit={timeLimit}
            active={listening}
            setEndGame={setEndGame}
            className="timer"
          />
          <div className={`rating-box `}>
            <Star className="star-icon" />
            <span>{score}</span>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default YesAndPage;
