import React from "react";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";
import { ActivityData } from "../model/ActivityData";
import { withRouter } from "react-router";

class ActivityPage extends React.Component {
  state = {
    currentId: 0,
    focusedId: 2,
  };
  constructor(props) {
    super(props);
    this.state = {
      currentId: 0,
      focusedId: 2,
    };
  }

  cardHoverHandle(id) {
    this.setState({ focusedId: id });
  }

  render() {
    return (
      <div className="activity full-screen-container">
        <div className="activity contents">
          <div className="title-container">
            <Typography
              variant="h4"
              component="h4"
              className="short-paragraphy text-white"
            >
              Practice with <strong>Skyler</strong>
            </Typography>
            <span className="flex-gap" />
          </div>
          <div className="cards container">
            {ActivityData.map((activity, index) => {
              return (
                <Button
                  key={index}
                  onClick={() => {
                    if (activity.id === 1) {
                      this.props.history.push("/chat");
                    } else if (activity.id === 2) {
                      this.props.history.push("/repeat");
                    } else if (activity.id === 3) {
                      this.props.history.push("/yesand");
                    } else if (activity.id === 4) {
                      this.props.history.push("/interview");
                    } else if (activity.id === 5) {
                      this.props.history.push("/say-again");
                    }
                  }}
                >
                  <img
                    src={activity.img}
                    alt={activity.name}
                    className={
                      this.state.focusedId === activity.id
                        ? "card-img focused"
                        : "card-img"
                    }
                    style={
                      activity.name === "Interview"
                        ? {
                            minWidth: "245px",
                          }
                        : null
                    }
                    onMouseEnter={() => this.cardHoverHandle(activity.id)}
                  />
                </Button>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ActivityPage);
