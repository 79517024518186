import React from 'react'

export default () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.0362 12L23.5781 2.45837C24.1406 1.89606 24.1406 0.984297 23.5781 0.421979C23.016 -0.14034 22.1038 -0.14034 21.5419 0.421979L12 9.96364L2.45837 0.421739C1.89606 -0.14058 0.984058 -0.14058 0.421979 0.421739C-0.14034 0.984058 -0.14034 1.89582 0.421979 2.45813L9.96364 12L0.421739 21.5419C-0.14058 22.1042 -0.14058 23.016 0.421739 23.5781C0.703018 23.8594 1.07142 24 1.44006 24C1.8087 24 2.17709 23.8594 2.45837 23.5781L12 14.0362L21.5419 23.5781C21.823 23.8594 22.1916 24 22.56 24C22.9284 24 23.297 23.8594 23.5781 23.5781C24.1406 23.0158 24.1406 22.104 23.5781 21.5419L14.0362 12Z" fill="#A0A0A0"/>
    </svg>
  )
}

