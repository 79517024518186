import http from "./httpService";

const path = "/interview";
const apiEndpoint = window.apiUrl + path;

export async function getInterviewQuestion(
  words,
  convoId,
  section,
  timeElapsed
) {
  const response = await http.post(apiEndpoint + "/interview-question", {
    previousAnswer: words,
    userId: convoId,
    section: section,
    time: Date.now(),
    answerTime: timeElapsed,
  });
  if (response.data) return response.data;
  else
    return {
      reply: "Error, please refresh the page.",
      feedback: "Refresh the page",
    };
}

export async function initiateInterview(data) {
  const response = await http.post(apiEndpoint + "/initiate-interview", {
    username: data.username,
    industry: data.industry,
    position: data.position,
    timeLimit: data.timeLimit,
    initialTime: Date.now(),
  });
  if (response.data) return response.data;
  else
    return {
      reply: "Error, please refresh the page.",
      feedback: "Refresh the page",
    };
}

export async function getInterviewFeedback(userId) {
  const response = await http.post(apiEndpoint + "/interview-feedback", {
    userId: userId,
  });
  if (response.data) return response.data;
  else
    return {
      reply: "Error, please refresh the page.",
      feedback: "Refresh the page",
    };
}
