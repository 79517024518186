import React from "react";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import YesAndCard from "../assets/imgs/cards/yesand.png";
import RepeatCard from "../assets/imgs/cards/repeat.png";
import ListAltIcon from "@material-ui/icons/ListAlt";
import DialogContent from "@material-ui/core/DialogContent";

const ruleSet = {
  Repeat: {
    title: "Repeat",
    card: RepeatCard,
    rules: [
      "Listen carefully to what I say, and then repeat it back to me. ",
      "I will pause the timer as I speak, and then start it when it’s your turn",
      "If you didn’t say what I said, I will repeat it back to you.",
      "See how many rounds you can do before the clock hits zero! ",
    ],
  },
  YesAnd: {
    title: "Yes And",
    card: YesAndCard,
    rules: [
      "Start with 100 points",
      "Always use 'yes and', or lose 25 points!",
      "Use nouns from the previous sentence - or lose 10 points!",
      "+10 points for bringing earlier nouns back!",
      "You have 90 seconds... keep your score high!",
    ],
  },
};

export default function RulesDialog({ activity }) {
  const activityInfo = ruleSet[activity];

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="info-dialog">
      <div className="rule-icon">
        <IconButton onClick={() => handleClickOpen()}>
          <ListAltIcon></ListAltIcon>
        </IconButton>
        <label>
          <Typography variant="body1" className="text-white">
            Rule
          </Typography>
        </label>
      </div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <div className="dialog left-box">
          <img src={activityInfo["card"]} className="rule-image" alt=""></img>
        </div>
        <div className="dialog right-box">
          <MuiDialogTitle>
            <Typography
              variant="h4"
              component="p"
              className="text-bold text-blue title"
            >
              {activityInfo["title"]}
            </Typography>
            <IconButton
              className="close-btn"
              onClick={handleClose}
              variant="outlined"
              color="primary"
              autoFocus
            >
              <CloseIcon></CloseIcon>
            </IconButton>
          </MuiDialogTitle>
          <DialogContent>
            <div style={{ paddingBottom: "30px" }}>
              <ul className="rule-list">
                {activityInfo["rules"].map((element, index) => {
                  return <li key={index}>{element}</li>;
                })}
              </ul>
            </div>
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
}
