var volumemeter = require("volume-meter");

export function AudioRecorder({ setLevel }) {
  return new Promise((resolve) => {
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      const mediaRecorder = new MediaRecorder(stream);
      const audioChunks = [];
      let finalAudio;

      mediaRecorder.addEventListener("dataavailable", (event) => {
        audioChunks.push(event.data);
      });

      const start = () => {
        if (mediaRecorder.state !== "recording") mediaRecorder.start();
      };

      const stop = () => {
        return new Promise((resolve) => {
          mediaRecorder.addEventListener("stop", () => {
            const audioBlob = new Blob(audioChunks);
            const audioUrl = URL.createObjectURL(audioBlob);
            const audio = new Audio(audioUrl);
            const play = () => {
              audio.play();
            };

            finalAudio = { audioBlob, audioUrl, play };
            resolve(true);
          });

          if (mediaRecorder.state !== "inactive") mediaRecorder.stop();
        }); // stop each of them
      };

      const exportAudio = () => {
        audioChunks.length = 0;
        return finalAudio;
      };

      // below calculates the microphone level and sets the parent state
      var ctx = new AudioContext();

      var meter = volumemeter(ctx, { tweenIn: 2, tweenOut: 6 }, function (
        volume
      ) {
        setLevel(volume);
      });

      var src = ctx.createMediaStreamSource(stream);
      src.connect(meter);
      src.connect(ctx.createGain());

      stream.onended = meter.stop.bind(meter);

      resolve({ start, stop, exportAudio });
    });
  });
}
