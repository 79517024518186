import axios from "axios";

// Separate backend address for python model
const http = axios.create({
  baseURL: window.apiUrlPy,
});

const apiEndpoint = window.apiUrlPy;

export async function sendStatement(text) {
  const response = await http.post(apiEndpoint + "/response", null, {
    params: { user_input: text },
  });
  if (response.data) return response.data[0];
  else
    return {
      reply: "Error, please refresh the page.",
      feedback: "Refresh the page",
    };
}

export async function addBotResponse(statement, prevStatement) {
  console.log(statement, prevStatement);
  // const response = await http.post(apiEndpoint + "/add", {
  //   statement: statement,
  //   prevStatement: prevStatement,
  // });
  // return response.data.text;
}

export async function editBotResponse(statement, desiredStatement) {
  console.log(statement, desiredStatement);

  // const response = await http.post(apiEndpoint + "/edit", {
  //   statement: statement,
  //   desiredStatement: desiredStatement,
  // });
  // return response.data.text;
}

export async function removeBotResponse(statement) {
  console.log(statement);

  // const response = await http.post(apiEndpoint + "/remove", {
  //   statement: statement,
  // });
  // return response.data.text;
}
