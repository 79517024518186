import React, { useState, useEffect, useRef } from "react";
import Webcam from "react-webcam";
import ReactDOM from "react-dom";

const WebcamStreamCapture = ({
  isStarted,
  isEnded,
  downloading,
  setDownloading,
  setUrl,
  playback,
  onRender,
}) => {
  const webcamRef = useRef(null);
  const isMounted = useRef(true);
  const mediaRecorderRef = useRef(null);
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);

  useEffect(() => {
    if (downloading) handleDownload();
    if (playback) handlePlayback();
  }, [downloading, playback]);

  useEffect(() => {
    if (
      isStarted &&
      !isEnded &&
      !capturing &&
      webcamRef.current.state.hasUserMedia
    ) {
      handleStartCaptureClick();
    } else if (
      isEnded &&
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state === "recording"
    ) {
      handleStopCaptureClick();
    }
  });

  const handleStartCaptureClick = React.useCallback(() => {
    setCapturing(true);
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: "video/webm",
    });
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
  }, [webcamRef, setCapturing, mediaRecorderRef]);

  const handleDataAvailable = React.useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const handleStopCaptureClick = React.useCallback(() => {
    mediaRecorderRef.current.stop();
    setCapturing(false);
  }, [mediaRecorderRef, webcamRef, setCapturing]);

  const handleDownload = React.useCallback(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: "video/webm",
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = url;
      a.download = "react-webcam-stream-capture.webm";
      a.click();
      window.URL.revokeObjectURL(url);
      setRecordedChunks([]);
    }
    setDownloading(false);
  }, [recordedChunks]);

  const handlePlayback = React.useCallback(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: "video/webm",
      });
      const url = URL.createObjectURL(blob);
      setUrl(url);
      setRecordedChunks([]);
    }
    setDownloading(false);
  }, [recordedChunks]);

  return (
    <React.Fragment>
      <Webcam
        className="mirror"
        audio={true}
        ref={webcamRef}
        onUserMedia={onRender}
      />
      {/* {capturing ? (
        <button onClick={handleStopCaptureClick}>Stop Capture</button>
      ) : (
        <button onClick={handleStartCaptureClick}>Start Capture</button>
      )}
       */}
      {/* {recordedChunks.length > 0 && (
        <button onClick={handleDownload}>Download</button>
      )} */}
    </React.Fragment>
  );
};

// export default ReactDOM.render(
//   <WebcamStreamCapture />,
//   document.getElementById("root")
// );
export default WebcamStreamCapture;

// https://www.npmjs.com/package/react-webcam
