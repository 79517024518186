import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";

export default function TextBox({ exchangeDialogue }) {
  const [text, setText] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    exchangeDialogue(text);
    setText("");
  };

  const handleChange = (e) => {
    setText(e.target.value);
  };

  return (
    <div className="message-box">
      <form onSubmit={handleSubmit}>
        <TextField
          variant="outlined"
          label="User Message"
          value={text}
          onChange={handleChange}
          className="text-input"
          type="text"
          autoFocus
          color="secondary"
        />
      </form>
    </div>
  );
}
