import React, {
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  useReducer,
} from "react";
import Dictaphone from "../components/Dictaphone";
import Webcam from "../components/Webcam";
import Avatar from "../components/Avatar";
import {
  getInterviewQuestion,
  initiateInterview,
  getInterviewFeedback,
} from "../services/interviewService";
import { Grid } from "@material-ui/core";
import SectionButton from "../assets/svgs/interview/SectionButton.jsx";
import MicIndicator from "../assets/svgs/interview/MicIndicator.jsx";
import AccentButton from "../assets/svgs/interview/AccentButton.jsx";
import NextQuestionButton from "../assets/svgs/interview/NextQuestionButton.jsx";
import RepeatQuestionButton from "../assets/svgs/interview/RepeatQuestionButton.jsx";
import EndButton from "../assets/svgs/interview/EndButton.jsx";
import InterviewResult from "../components/InterviewResult";
import SectionButtons from "../assets/svgs/interview/SectionButtons.jsx";
import Slider from "@material-ui/core/Slider";
import RecordingButton from "../assets/svgs/interview/RecordingButton.jsx";
import ReviewButton from "../assets/svgs/interview/ReviewButton.jsx";
import ReactPlayer from "react-player";
import _ from "lodash";
import SpeechSynthesis from "../utils/SpeechSynthesis";

let recentDialogue = "";
let SKYLER_INTRO = [
  "Hi, I am Skyler, welcome to our mockup interview! Before we start, please let us know more about you.",
  "Thanks! The interview has five sections: Intro; Behavioural;  Skill-based; Situational and Closing. By default, you will be asked questions from each section, but you can practice any section that you want!",
  "Now please select your expected practice time.",
  "Great! Please record a clip to test your audio and video.",
  "Start your interview whenever you are ready! Once you finish a question, click on “Next Question” button.",
];

const allVoicesObtained = new Promise(function (resolve, reject) {
  let voices = window.speechSynthesis.getVoices();
  if (voices.length !== 0) {
    resolve(voices);
  } else {
    window.speechSynthesis.addEventListener("voiceschanged", function () {
      voices = window.speechSynthesis.getVoices();
      resolve(voices);
    });
  }
});

function dialogueReducer(state, action) {
  switch (action.type) {
    case "add":
      const updatedQueue = Array.isArray(action.data)
        ? [...state.dialogueQueue, ...action.data]
        : [...state.dialogueQueue, action.data];
      return { dialogueQueue: updatedQueue };
    case "shift":
      const currentDialogue = state.dialogueQueue.shift();
      recentDialogue = currentDialogue;
      return {
        dialogueQueue: state.dialogueQueue,
        currentDialogue: currentDialogue,
      };
    case "clear":
      return {
        dialogueQueue: [],
        currentDialogue: "",
      };
    default:
      throw new Error();
  }
}

function animationReducer(state, action) {
  switch (action.type) {
    case "change":
      return action.data;
    default:
      throw new Error();
  }
}

function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function InterviewPage() {
  const [animation, dispatchAnimation] = useReducer(animationReducer, "listen");
  const [dialogueState, dispatchDialogue] = useReducer(dialogueReducer, {
    dialogueQueue: [],
    currentDialogue: "",
  });

  const isMounted = useRef(true);
  const blockExchange = useRef(true);

  // general game states
  const [convoId, setId] = useState(false);
  const [isStarted, setStartGame] = useState(false);
  const [isEnded, setEndGame] = useState(false);
  const [listening, setListening] = useState(false);
  const [speaking, setSpeaking] = useState();

  // onboarding states
  const [step, setStep] = useState(1);
  const [usersName, setUsersName] = useState("");
  const [position, setPosition] = useState("");
  const [industry, setIndustry] = useState("");
  const [interviewTime, setInterviewTime] = useState(10);
  const [testRecording, setTestRecording] = useState(false);
  const [stopTestRecording, setStopTestRecording] = useState(false);
  const [reviewingVideo, setReviewingVideo] = useState(false);
  const [finishedReviewing, setFinishReviewing] = useState(false);
  const [videoUrl, setUrl] = useState();
  const [fullScreenCam, setFullScreenCam] = useState(false);
  const [recordButtonVisible, showRecordButton] = useState(false);

  // interview states
  const [showAccentMenu, setShowAccentMenu] = useState(false);
  const [voiceOptions, setVoiceOptions] = useState([]);
  const [selectedVoice, setSelectedVoice] = useState();
  const [showSectionMenu, setSectionMenu] = useState(false);
  const [selectedSection, setSelectedSection] = useState(0);
  const [currentSection, setCurrentSection] = useState(0);

  // end game states
  const [feedback, setFeedback] = useState({});
  const [downloading, setDownloading] = useState(false);
  const [answerTime, setAnswerTime] = useState();

  // automatically initiate interview onboarding
  useLayoutEffect(() => {
    const delayedIntro = async () => {
      await timeout(1500);
      addToDialogueQueue(SKYLER_INTRO[0]);
    };
    delayedIntro();
  }, []);

  // bot 'speaking' hook to control animation & listening state
  useLayoutEffect(() => {
    if (speaking) {
      dispatchAnimation({ type: "change", data: "talk" });
    } else dispatchAnimation({ type: "change", data: "listen" });
    if (
      !dialogueState.dialogueQueue.length &&
      !speaking &&
      !blockExchange.current
    )
      setListeningTimed(true);
  }, [speaking]);

  // automatically speak relevant intro dialogue based on user progress on onboarding page
  useEffect(() => {
    dispatchDialogue({ type: "clear" });
    if (step === 2) {
      addToDialogueQueue([SKYLER_INTRO[1], SKYLER_INTRO[2]]);
    } else if (step === 3) {
      addToDialogueQueue(SKYLER_INTRO[3]);
    } else if (step === 4) {
      addToDialogueQueue(SKYLER_INTRO[4]);
    }
  }, [step]);

  // set the next dialogue to be spoken from the dialogue queue
  useEffect(() => {
    async function shiftDialogueQueue() {
      await timeout(1000);
      dispatchDialogue({ type: "shift" });
    }
    if (!speaking && dialogueState.dialogueQueue.length) {
      shiftDialogueQueue();
    }
  }, [dialogueState.dialogueQueue, speaking]);

  // must wait to set voice options since chrome loads them async
  useEffect(() => {
    const loadVoices = async () => {
      let voices = await allVoicesObtained;
      voices = voices.filter((voice) => {
        return _.startsWith(voice.lang, "en");
      });
      setSelectedVoice("Google US English");
      setVoiceOptions(voices);
    };
    loadVoices();
    return () => {
      isMounted.current = false;
    };
  }, []);

  function addToDialogueQueue(statement) {
    dispatchDialogue({ type: "add", data: statement });
  }

  async function onBotSpeakingEnd() {
    const nextDialogue = dialogueState.dialogueQueue.length;
    if (nextDialogue) {
      dispatchDialogue({ type: "shift" });
    }
  }

  function setListeningTimed(toggle) {
    if (toggle) {
      setAnswerTime(Date.now());
      setListening(true);
    } else {
      setListening(false);
    }
  }

  async function exchangeDialogue(words) {
    if (!blockExchange.current) {
      const timeElapsed = Date.now() - answerTime;
      const response = await getInterviewQuestion(
        words,
        convoId,
        selectedSection,
        timeElapsed
      );
      setCurrentSection(response.section);
      if (response.endGameOverride) endGame();
      else {
        addToDialogueQueue(response.question);
      }
    }
  }

  // initiate new game session
  async function initiate() {
    setSpeaking(false);
    dispatchDialogue({ type: "clear" });
    setStartGame(true);
    const initiatedGame = await initiateInterview({
      username: usersName,
      industry: industry,
      position: position,
      timeLimit: interviewTime,
    });
    const firstQuestion = await getInterviewQuestion(
      null,
      initiatedGame.userId
    );
    setId(initiatedGame.userId);
    setListening(false);
    await timeout(1000);
    addToDialogueQueue([
      "Welcome to our mockup interview! Let's jump in!",
      firstQuestion.question,
    ]);
    blockExchange.current = false;
  }

  const restartTheGame = () => {
    setStartGame(false);
    setEndGame(false);

    dispatchDialogue({ type: "clear" });

    setId(false);
    setSelectedSection(0);
    setCurrentSection(0);

    setDownloading(false);
    setStep(1);
    setTestRecording(false);
    setStopTestRecording(false);
    setReviewingVideo(false);
    setFinishReviewing(false);
    setUrl(null);

    setFeedback({});
    showRecordButton(false);
    dispatchAnimation({ type: "change", data: "listen" });
  };

  const exchangeOverride = () => {
    if (blockExchange.current) blockExchange.current = false;
    if (listening) setListening(false);
  };

  const endGame = async () => {
    if (!isEnded) {
      blockExchange.current = true;
      const feedback = await getInterviewFeedback(convoId);
      if (feedback.analysisSummary) setFeedback(feedback);
      console.log(feedback);
      setEndGame(true);
    }
  };

  async function handleRepeat() {
    // let synthesis = new SpeechSynthesis();
    // await synthesis.load(selectedVoice);
    // synthesis.speak(recentDialogue);
    addToDialogueQueue(recentDialogue);
  }

  const ChatBubble = (content, role) => {
    return (
      <div className={`${role} chat-bubble`}>
        <span className="chat-content">{content}</span>
      </div>
    );
  };

  const input = (title, currentValue, setValue) => {
    return (
      <div className="onboard-input">
        <div className="input-title">{title}</div>
        <div className="user-input-container">
          <input
            className="user-input"
            value={currentValue}
            placeholder={title}
            onChange={(e) => {
              setValue(e.target.value);
            }}
          />
        </div>
      </div>
    );
  };

  const nextStep = () => {
    setStep(step + 1);
  };

  const onBoardInputs = () => {
    function onNext() {
      nextStep();
    }
    return (
      <div className="onboard-input-container slow-fade">
        <div>
          {input("Name", usersName, setUsersName)}
          {input("Position", position, setPosition)}
          {input("Industry", industry, setIndustry)}
        </div>
        <button className="next-button" onClick={() => onNext()}>
          Next
        </button>
      </div>
    );
  };

  const timerInput = () => {
    function onNext() {
      nextStep();
    }
    return (
      <div>
        <div className="slider-title">Practice Minutes</div>
        <div className="slider">
          <Slider
            defaultValue={10}
            valueLabelDisplay="off"
            step={10}
            min={10}
            max={60}
            marks={[10, 20, 30, 40, 50, 60].map((val) => {
              return { value: val, label: val.toString() };
            })}
            // value={interviewTime}
            onChange={(event, newValue) => {
              setInterviewTime(newValue);
            }}
          />
        </div>
        <button className="next-button" onClick={() => onNext()}>
          Next
        </button>
      </div>
    );
  };

  const startButton = () => {
    return (
      <button
        className="onboard-start-button"
        onClick={async () => {
          initiate();
        }}
      >
        <span className="button-text">Let's Start!</span>
      </button>
    );
  };

  return (
    <div
      className={
        !isStarted
          ? "repeat chat full-screen-container"
          : "interview full-screen-container"
      }
    >
      <div
        className={
          listening && isStarted
            ? "interview-mic-indicator mic-listening-container"
            : isStarted
            ? "interview-mic-indicator"
            : isEnded
            ? "hidden"
            : null
        }
      >
        {isStarted && !isEnded ? (
          <div className="interview-mic-icon">
            <MicIndicator listening={listening} />
          </div>
        ) : null}
        {!isEnded ? (
          <div className="interview-levels">
            <Dictaphone
              listening={!isStarted ? false : listening}
              setListening={setListeningTimed}
              speaking={speaking}
              setSpeaking={setSpeaking}
              currentDialogue={dialogueState.currentDialogue}
              exchangeDialogue={exchangeDialogue}
              onEnd={() => {
                onBotSpeakingEnd();
              }}
              showLevels={isStarted}
              shouldCancel={!dialogueState.currentDialogue}
              manualListen
              invisible
              voice={selectedVoice}
            />
          </div>
        ) : null}
      </div>
      <div
        className={
          !isStarted
            ? "onboard-avatar"
            : fullScreenCam
            ? "avatar-upper-right"
            : "avatar-container-before"
        }
      >
        <Avatar className="avatar-image" mood={animation} />
      </div>
      {!isStarted ? (
        <div>
          <div className="onboarding-conversation-container">
            {step === 1 ? (
              <div>
                <div className="fast-fade">
                  {ChatBubble(SKYLER_INTRO[0], "ai")}
                </div>
                <div className="slow-fade">
                  {ChatBubble(onBoardInputs(), "input")}
                </div>
              </div>
            ) : step === 2 ? (
              <div>
                <div className="fast-fade">
                  {ChatBubble(SKYLER_INTRO[1], "ai")}
                  {ChatBubble(SKYLER_INTRO[2], "ai")}
                </div>
                <div className="slow-fade">
                  {ChatBubble(timerInput(), "input")}
                </div>
              </div>
            ) : step === 3 || 4 ? (
              <div className={step === 4 ? "shiftup" : null}>
                <div className="onboard-testing">
                  {!finishedReviewing && testRecording !== "already" ? (
                    <div className="fast-fade">
                      {ChatBubble(SKYLER_INTRO[3], "ai")}
                    </div>
                  ) : null}
                  <div className="onboarding-webcam-container">
                    <div className="onboarding-webcam slow-fade">
                      <Webcam
                        isStarted={testRecording}
                        isEnded={stopTestRecording}
                        mirrored
                        setDownloading={setDownloading}
                        downloading={downloading}
                        setUrl={setUrl}
                        playback={reviewingVideo}
                        onRender={async () => {
                          await timeout(1000);
                          showRecordButton(true);
                        }}
                      />
                    </div>
                    <div className="onboarding-player">
                      {videoUrl && (
                        <ReactPlayer
                          url={videoUrl}
                          playing={reviewingVideo}
                          onEnded={() => {
                            setFinishReviewing(true);
                            setTestRecording(false);
                            setReviewingVideo(false);
                            setStopTestRecording(false);
                            if (step === 3) nextStep();
                          }}
                        />
                      )}
                    </div>
                  </div>

                  {recordButtonVisible && (
                    <div className="onboard-record-button slow-fade">
                      {!stopTestRecording ? (
                        <RecordingButton
                          recording={testRecording}
                          onClick={() => {
                            if (testRecording) setStopTestRecording(true);
                            else {
                              setUrl(null);
                              // clean views for when user re-records clip
                              if (finishedReviewing) {
                                setFinishReviewing(false);
                                setTestRecording("already");
                              } else {
                                setTestRecording(true);
                              }
                            }
                          }}
                        />
                      ) : (
                        <ReviewButton
                          onClick={() => {
                            setReviewingVideo(true);
                          }}
                          reviewing={reviewingVideo}
                        />
                      )}
                    </div>
                  )}
                </div>
                {finishedReviewing && (
                  <div className="onboard-start-container">
                    <div className="chat-bubble-onboard ">
                      {ChatBubble(SKYLER_INTRO[4], "ai")}
                      {ChatBubble(startButton(), "human")}
                    </div>
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </div>
      ) : (
        <div>
          {isStarted ? (
            <div>
              {isEnded && feedback ? (
                <InterviewResult
                  closeFunction={restartTheGame}
                  setDownloading={setDownloading}
                  feedback={feedback}
                  setDownloading={setDownloading}
                />
              ) : null}
              <div
                className={
                  isEnded
                    ? "hidden"
                    : fullScreenCam
                    ? "webcam-full-screen"
                    : "webcam-container-after"
                }
                onClick={() => setFullScreenCam(!fullScreenCam)}
              >
                <Webcam
                  isStarted={isStarted}
                  isEnded={isEnded}
                  mirrored
                  setDownloading={setDownloading}
                  downloading={downloading}
                />
              </div>
            </div>
          ) : null}
          {!isEnded ? (
            <div>
              <footer className="interview-controls">
                <Grid container justify="space-between" alignItems="center">
                  <Grid item xs={4}>
                    <div
                      className={
                        showSectionMenu
                          ? "section-button-padding section-button-background"
                          : "section-button-padding"
                      }
                    >
                      <SectionButton
                        onClick={() => setSectionMenu(!showSectionMenu)}
                      />
                    </div>
                    <div className="accent-button">
                      <AccentButton
                        onClick={() => setShowAccentMenu(!showAccentMenu)}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div className="menu-mid-button-container">
                      <NextQuestionButton onClick={exchangeOverride} />
                      <RepeatQuestionButton onClick={handleRepeat} />
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div className="end-button">
                      <EndButton onClick={endGame} />
                    </div>
                  </Grid>
                </Grid>
              </footer>
              {showSectionMenu && (
                <div className="section-menu">
                  <div className="section-buttons">
                    <ul className="no-bullets">
                      {[
                        "Intro",
                        "Behavioural",
                        "Skill-Based",
                        "Situational",
                        "Closing",
                      ].map((section, i) => {
                        return (
                          <li key={i} className="section-button-background">
                            <SectionButtons
                              text={section}
                              onClick={() => {
                                if (selectedSection === i + 1) {
                                  setSelectedSection(0);
                                  setCurrentSection("deselect");
                                  setSectionMenu(false);
                                } else {
                                  setSelectedSection(i + 1);
                                  setCurrentSection(i);
                                  setSectionMenu(false);
                                }
                              }}
                              selected={currentSection === i}
                            />
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              )}
              {showAccentMenu && (
                <div className="accent-menu">
                  <div className="section-buttons">
                    <ul className="no-bullets">
                      {voiceOptions.map((voice, i) => {
                        return (
                          <li key={i} className="section-button-background">
                            <SectionButtons
                              text={voice.name + " - " + voice.lang}
                              onClick={() => {
                                setSelectedVoice(voice.name);
                                setShowAccentMenu(false);
                              }}
                              selected={voice.name === selectedVoice}
                            />
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              )}
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
}

export default InterviewPage;
