import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import VolumeSvg from "../components/VolumeSvg";
import { createMuiTheme } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import "../App.css";

const PerfectSentences = ({ diffs }) => {
  var index = 1;
  var theme = createMuiTheme();

  return (
    <div>
      {diffs.map((f) => {
        return (
          <Card className="card">
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={1}>
                  <Avatar
                    style={{
                      color: "#FFFFFF",
                      backgroundColor: "#5264E5",
                      width: theme.spacing(2),
                      height: theme.spacing(2),
                      fontSize: "10px",
                    }}
                  >
                    {index++}
                  </Avatar>
                </Grid>

                <Grid item xs={10}>
                  <span className="standard-text">{f.before}</span>
                </Grid>

                <Grid item xs={1}>
                  <VolumeSvg />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        );
      })}
    </div>
  );
};

export default PerfectSentences;
