import React from "react";
import { Route, Switch, HashRouter } from "react-router-dom";
import "./App.css";
import "./assets/webfonts/Montserrat.css";
import Header from "./components/Header/Header";
import ChatPage from "./containers/ChatPage";
import Repeat from "./containers/RepeatPage";
import YesAndPage from "./containers/YesAndPage";
import LandingPage from "./containers/LandingPage.jsx";
import TutorialPage from "./containers/Tutorial/TutorialPage";
import ActivityPage from "./containers/ActivityPage";
import ChatPlaybackPage from "./containers/ChatPlayback/ChatPlaybackPage";
import AdminChat from "./containers/AdminChat/AdminChatPage";
import InterviewPage from "./containers/InterviewPage";
import SayAgainPage from "./containers/SayAgainPage";

function App() {
  return (
    <div className="app-container">
      <HashRouter>
        <Header brandName="Improvbot" fixed color="primary" absolute />
        <Switch>
          <Route path="/landing" component={LandingPage} />
          <Route path="/chat" component={ChatPage} />
          <Route path="/admin-chat" component={AdminChat} />
          <Route path="/repeat" component={Repeat} />
          <Route path="/yesand" component={YesAndPage} />
          <Route path="/tutorial" component={TutorialPage} />
          <Route path="/activity" component={ActivityPage} />
          <Route path="/chat-playback" component={ChatPlaybackPage} />
          <Route path="/interview" component={InterviewPage} />
          <Route path="/say-again" component={SayAgainPage} />
          <Route path="/" component={LandingPage} />
        </Switch>
      </HashRouter>
    </div>
  );
}

export default App;
