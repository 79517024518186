import http from "./httpService";

const repeatPath = "/repeat";
const apiEndpoint = window.apiUrl + repeatPath;

export async function skipStatement(convoId) {
  const nextEntry = await http.post(apiEndpoint + "/skip", {
    convoId: convoId,
  });
  return nextEntry.data.text;
}

export async function sendRepeatStatement(text, convoId) {
  const response = await http.post(apiEndpoint + "/sentence", {
    text: text,
    convoId: convoId,
  });
  if (response.data) return response.data;
  else
    return {
      nextEntry: "Error, please refresh the page.",
      feedback: "Refresh the page",
    };
}

export async function initiateGame(text) {
  const response = await http.get(apiEndpoint + "/initiate-game", {
    text: text,
  });
  if (response.data) return response.data;
  else
    return {
      nextEntry: "Error, please refresh the page.",
      feedback: "Refresh the page",
    };
}

export async function getGameResult(convoId) {
  const response = await http.post(apiEndpoint + "/game-result", {
    convoId: convoId,
  });
  if (response.data) return response.data;
  else
    return {
      nextEntry: "Error, please refresh the page.",
      feedback: "Refresh the page",
    };
}
