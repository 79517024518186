import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import winking from "../assets/imgs/winking.png";
import BotImage from "../assets/svgs/LandingPageBot.jsx";
import ChooseActivity from "../assets/svgs/ChooseActivity.jsx";
import ReviewResults from "../assets/svgs/ReviewResults.jsx";
import BrandIcon from "../assets/imgs/ib_logo.svg";

function LandingPage() {
  let history = useHistory();
  const scrollRef = useRef(null);

  return (
    <div container className="landing landing-full-screen-container">
      <div className="landing-p1-container">
        <div className="landing left-box">
          <div className="landing header">Meet Skyler, the ImprovBot!</div>
          <div className="landing subheader">
            Build your confidence through tailored activities aimed at
            developing your soft skills.
          </div>
          <button
            onClick={() => {
              window.scrollTo({
                top: 2700,
                left: 0,
                behavior: "smooth",
              });
            }}
            className="landing-button"
          >
            How does it work?
          </button>
        </div>
      </div>

      <div className="landing right-box">
        <BotImage />
        <div className="landing-bubbles">
          <div className="bubble-container">
            <div className="landing chat-bubble">
              <div className="landing bubble-text">
                Hey! I’m Skyler, nice to meet you! How are you today?
              </div>
            </div>
          </div>
          <div className="bubble-container-two">
            <div className="landing chat-bubble-rev">
              <div className="landing bubble-text">
                I’m doing well. Thanks for asking!
              </div>
            </div>
          </div>
          <div className="landing chat-bubble">
            <div className="landing bubble-text">
              That’s fantastic! Tell me about your plans for the week!
            </div>
          </div>
        </div>
      </div>

      <div className="landing-flare" />
      <div className="landing-title-1">
        Practice your speaking skills on your own
      </div>

      <div className="landing-card-1">
        <div className="landing-card-rectangle" />
        <div className="landing-card-image image-1" />
        <div className="landing-card-header">
          It's more than just your speaking skills
        </div>
        <div className="landing-card-text">
          Although ImprovBot focuses on your speaking skills, it also helps with
          improving your listening and improvization skills.
        </div>
        <div className="landing-card-colour colour-1" />
      </div>

      <div className="landing-card-2">
        <div className="landing-card-rectangle" />
        <div className="landing-card-image image-2" />
        <div className="landing-card-header">
          Practice when you want, where you want, and as much as you want
        </div>
        <div className="landing-card-text">
          ImprovBot is free to use so you can practice as much as you need until
          you’re feeling confident.
        </div>
        <div className="landing-card-colour colour-2" />
      </div>

      <div className="landing-card-3">
        <div className="landing-card-rectangle" />
        <div className="landing-card-image image-3" />
        <div className="landing-card-header">
          Be ready to tackle the next interview, job, or other future endeavor
        </div>
        <div className="landing-card-text">
          Whether you’re preparing for the next big interview, or starting a new
          clinical practicum, you’ll need sharp soft-skills to succeed!
        </div>
        <div className="landing-card-colour colour-3" />
      </div>

      <div className="landing-card-4">
        <div className="landing-card-rectangle" />
        <div className="landing-card-image image-4" />
        <div className="landing-card-header">
          ImprovBot is here to support your learning and growth
        </div>
        <div className="landing-card-text">
          After each activity, Skyler provides feedback to help you learn how to
          improve. Listen back to your recording and reflect on any mistakes.
        </div>
        <div className="landing-card-colour colour-4" />
      </div>
      <div className="landing-title-2">
        Activities designed with your{" "}
        <span className="title-highlight">success</span> in mind
      </div>

      <div className="repeat-card" />
      <div className="repeat-subheader">Repeat After Me</div>
      <div className="repeat-content">
        This game focuses on your listening abilties. Listen carefully to what
        Skyler says, and try to repeat the phrases back to her as close as
        possible. The closer you are to what Skyler says, the more points you’ll
        earn!
      </div>
      <div className="goto-1">
        <div
          className="goto-button"
          onClick={() =>
            history.push({
              pathname: "/repeat",
            })
          }
        >
          Go to activity
        </div>
      </div>

      <div className="yes-card" />
      <div className="yes-subheader">Yes And...</div>
      <div className="yes-content">
        Skyler will begin part of a story, and it’s your job to keep the story
        going by starting your replies with “Yes and ...” The story will
        alternate between Skyler and yourself, so think creatively and use your
        quick thinking to keep the story going!
      </div>
      <div className="goto-2">
        <div
          className="goto-button"
          onClick={() =>
            history.push({
              pathname: "/yesand",
            })
          }
        >
          Go to activity
        </div>
      </div>

      <div className="free-talk-card" />
      <div className="free-subheader">Free Talk</div>
      <div className="free-content">
        Here, you’ll have a chance to speak freely with Skyler. After
        introducing herself, she will ask you various questions which you can
        try your best to answer. Even if you don’t know the answer, try to keep
        the conversation going!
      </div>
      <div className="goto-3">
        <div
          className="goto-button"
          onClick={() =>
            history.push({
              pathname: "/chat",
            })
          }
        >
          Go to activity
        </div>
      </div>

      <div className="landing-rectangle" />

      <div className="tutorial-subheader">How does it work?</div>
      <div className="activity-image">
        <ChooseActivity />
      </div>
      <div className="activity-rectangle" />
      <div className="activity-rectangle-accent" />
      <div className="activity-card-title">
        Step 1: Choose an{" "}
        <span className="activity-card-highlight">Activity</span>
      </div>
      <div className="activity-card-body">
        Before talking with Skyler, please choose an improv activity. Each
        activity is different, so be sure to read the rules carefully. Try to
        keep Skyler happy by following these rules during the conversation!
      </div>

      <div className="review-image">
        <ReviewResults />
      </div>
      <div className="review-rectangle" />
      <div className="review-rectangle-accent" />
      <div className="review-card-title">
        Step 2: <span className="review-card-highlight">Review</span>
        {" & Reflect"}
      </div>
      <div className="review-card-body">
        When the activity is over, you’ll get to see your score and how you
        performed. You can download the recording and review it to understand
        where you made mistakes and how you can improve your soft skills in the
        future!
      </div>

      <div
        className="get-started-button"
        onClick={() =>
          history.push({
            pathname: "/activity",
          })
        }
      >
        <div className="button-text-gs">Get Started</div>
      </div>
      <div className="footer-brand">
        <img className="navBrand" src={BrandIcon} alt="brand icon" />
        <span className="brandTitle text-white">ImprovBot</span>
      </div>

      <div className="footer-activities">
        <div
          className="footer-mainlink"
          onClick={() =>
            history.push({
              pathname: "/activity",
            })
          }
        >
          Activities
        </div>
      </div>
      <div className="footer-repeat">
        <div
          className="footer-sublink"
          onClick={() =>
            history.push({
              pathname: "/repeat",
            })
          }
        >
          Repeat After Me
        </div>
      </div>
      <div className="footer-yes">
        <div
          className="footer-sublink"
          onClick={() =>
            history.push({
              pathname: "/yesand",
            })
          }
        >
          Yes And...
        </div>
      </div>
      <div className="footer-free">
        <div
          className="footer-sublink"
          onClick={() =>
            history.push({
              pathname: "/chat",
            })
          }
        >
          Free Talk
        </div>
      </div>
      <div className="footer-playback">
        <div
          className="footer-mainlink"
          onClick={() =>
            history.push({
              pathname: "/chat-playback",
            })
          }
        >
          Playback
        </div>
      </div>
      <div className="footer-about">
        <div
          className="footer-mainlink"
          onClick={() =>
            history.push({
              pathname: "/",
            })
          }
        >
          About Us
        </div>
      </div>

      <div className="footer-media-border" />
      <div className="footer-media-title">Follow us!</div>
      <div className="footer-facebook" />
      <div className="footer-insta" />
      <div className="footer-linkedin" />

      <div className="footer-ubc-logo" />
      <div className="footer-cdm-logo" />
      <div className="footer-location">
        Life Sciences Centre, Rm 1516 2350 Health Sciences Mall
      </div>
    </div>
  );
}

export default LandingPage;
