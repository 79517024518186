const allVoicesObtained = new Promise(function (resolve, reject) {
  let voices = window.speechSynthesis.getVoices();
  if (voices.length !== 0) {
    resolve(voices);
  } else {
    window.speechSynthesis.addEventListener("voiceschanged", function () {
      voices = window.speechSynthesis.getVoices();
      resolve(voices);
    });
  }
});

export default class SpeechSynthesis {
  constructor(props) {
    this.utterance = new window.SpeechSynthesisUtterance();
    // this.selected = SpeechSynthesis.getVoice(props.voice);
    // this.utterance.voice = this.selected;
    this.utterance.lang = "en-US";
    this.utterance.pitch = 0.8;
    this.utterance.rate = 1;
    this.utterance.volume = 1;
    // parseFloat(props.volume, 10) ||
  }

  async load(voice) {
    this.selected = await SpeechSynthesis.getVoice(voice);
    this.utterance.voice = this.selected;
  }

  static supported(selected) {
    return window.speechSynthesis;
  }

  static async getVoice(selected) {
    const voices = await allVoicesObtained;
    const voice = voices.find((voice) => voice.name === selected);
    return voice !== undefined ? voice : voices[0];
  }

  async changeVoice(voice) {
    this.utterance.voice = await SpeechSynthesis.getVoice(voice);
  }

  onend(func) {
    this.utterance.onend = func;
  }

  onerror(func) {
    this.utterance.onerror = func;
  }

  speak(text) {
    window.speechSynthesis.cancel();
    this.utterance.text = text.replace(/\n/g, "");
    window.speechSynthesis.speak(this.utterance);
  }

  pause() {
    window.speechSynthesis.pause();
  }

  cancel() {
    window.speechSynthesis.cancel();
  }

  resume() {
    window.speechSynthesis.resume();
  }
}
