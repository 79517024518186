import React, { useState, useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const Timer = ({ timeLimit, active, setEndGame }) => {
  const [seconds, setSeconds] = useState(timeLimit);

  let progress = (seconds / timeLimit) * 100;
  var timer = null;

  useEffect(() => {
    if (active) {
      timer = setInterval(() => {
        setSeconds((seconds) => seconds - 1);
      }, 1000);
    }
    if (seconds === 0) {
      setEndGame(true);
      setSeconds(timeLimit);
    }
    return () => clearInterval(timer);
  }, [active, seconds]);

  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        variant="static"
        value={progress}
        style={{ color: "white", width: "116px" }}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="h2"
          component="div"
          style={{ color: "white", paddingLeft: "75px" }}
        >{`${Math.round(seconds)}`}</Typography>
      </Box>
    </Box>
  );
};

export default Timer;
