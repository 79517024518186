import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import Conversation from "../../components/ConversationLog";
import FileDrop from "../../components/FileDrop";
import Dictaphone from "../../components/Dictaphone";
import Avatar from "../../components/Avatar";
import "./ChatPlaybackPage.css";
import { useLocation } from "react-router-dom";

function ChatPlaybackPage() {
  const [conversation, setConversation] = useState([]);
  const [currentIndex, setIndex] = useState(null);
  const [dialogueFeed, setDialogue] = useState([]);
  const [isPlaying, setPlaying] = useState(null);
  let location = useLocation();

  useEffect(() => {
    const playConversation = () => {
      let updateDialogue = [conversation[0]];
      setPlaying(true);
      setDialogue(updateDialogue);
    };

    if (conversation.length) {
      playConversation();
    }
  }, [conversation]);

  // load conversation directly from previous page
  useEffect(() => {
    if (location.loadConversation) {
      setConversation(location.loadConversation);
    }
  }, []);

  const setNextLine = () => {
    let updateDialogue = [...dialogueFeed];
    if (currentIndex < conversation.length - 1) {
      updateDialogue.push(conversation[currentIndex + 1]);
      setIndex(currentIndex + 1);
      setDialogue(updateDialogue);
    } else newPlayBack();
  };

  const newPlayBack = () => {
    setPlaying(false);
    setConversation([]);
    setIndex([null]);
    setDialogue([]);
  };

  function isTalking(flag) {}

  return (
    <div className="chat playback full-screen-container">
      <Grid container justify="center" spacing={0} className="content">
        <Grid item xs={4} className="left-box">
          {isPlaying && (
            <div>
              <Avatar className="chat-bot-image" mood={"happy"} />
            </div>
          )}
          <div style={{ visibility: "hidden" }}>
            <Dictaphone
              activity="playback"
              conversation={dialogueFeed}
              dictaphoneOn={true}
              onAudioEnd={setNextLine}
              onEnd={setNextLine}
              setTalking={isTalking}
              playbackMode
            />
          </div>
        </Grid>

        {/* Upload Files: */}

        <Grid item xs={8} className="right-box">
          {!isPlaying ? (
            <div className="control-box">
              <FileDrop setConversation={setConversation} />
            </div>
          ) : (
            <Conversation conversation={dialogueFeed} />
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default ChatPlaybackPage;
