import React, { useState, useEffect, useRef } from "react";
import Dictaphone from "../components/Dictaphone";
import Avatar from "../components/Avatar";
import StartEndButton from "../components/StartEndButton";
import { initiateGame, getFeedback } from "../services/sayAgainService";
import _ from "lodash";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import ResultDialog from "../components/ResultDialog";
import SayAgainResults from "../components/SayAgainResults";

const OrangeTextTypography = withStyles({
  root: {
    color: "#ffba8d",
  },
})(Typography);

const testObject = {
  analysisSummary: {
    sadnessMean: 0.07923477777777778,
    joyMean: 0.12821788888888888,
    fearMean: 0.03790911111111111,
    disgustMean: 0.02432911111111111,
    angerMean: 0.029074444444444444,
    sentimentMean: -0.044058444444444435,
    averageWPM: 54.58767890409505,
  },
  individualAnalysis: {
    doubt: [
      {
        answer: "sure thing",
        emotion: undefined,
        sentiment: undefined,
        length: 2,
        wordsPerMinute: 44.79283314669652,
      },
      {
        answer: "I got nothing",
        emotion: undefined,
        sentiment: undefined,
        length: 3,
        wordsPerMinute: 82.87292817679557,
      },
      {
        answer: "not much really know",
        emotion: {
          document: {
            emotion: {
              sadness: 0.238554,
              joy: 0.076666,
              fear: 0.093798,
              disgust: 0.058437,
              anger: 0.09364,
            },
          },
        },
        sentiment: { document: { score: -0.913349, label: "negative" } },
        length: 4,
        wordsPerMinute: 82.38928939237898,
      },
    ],
    regret: [
      {
        answer: "much nothing",
        emotion: {
          document: {
            emotion: {
              sadness: 0.151545,
              joy: 0.174219,
              fear: 0.093654,
              disgust: 0.072945,
              anger: 0.08686,
            },
          },
        },
        sentiment: { document: { score: -0.902162, label: "negative" } },
        length: 2,
        wordsPerMinute: 54.79452054794521,
      },
      {
        answer: "no I can't tell you much",
        emotion: {
          document: {
            emotion: {
              sadness: 0.30172,
              joy: 0.133577,
              fear: 0.126961,
              disgust: 0.051779,
              anger: 0.070676,
            },
          },
        },
        sentiment: { document: { score: -0.775366, label: "negative" } },
        length: 6,
        wordsPerMinute: 105.4172767203514,
      },
      {
        answer: "okay sounds good",
        emotion: {
          document: {
            emotion: {
              sadness: 0.021294,
              joy: 0.769499,
              fear: 0.026769,
              disgust: 0.035801,
              anger: 0.010494,
            },
          },
        },
        sentiment: { document: { score: 0.966197, label: "positive" } },
        length: 3,
        wordsPerMinute: 53.876085004489674,
      },
    ],
    future: [
      {
        answer: "sure",
        emotion: {
          document: {
            emotion: { sadness: 0, joy: 0, fear: 0, disgust: 0, anger: 0 },
          },
        },
        sentiment: { document: { score: 0.614077, label: "positive" } },
        length: 1,
        wordsPerMinute: 23.273855702094647,
      },
      {
        answer: "okay",
        emotion: {
          document: {
            emotion: { sadness: 0, joy: 0, fear: 0, disgust: 0, anger: 0 },
          },
        },
        sentiment: { document: { score: 0, label: "neutral" } },
        length: 1,
        wordsPerMinute: 21.375133594584966,
      },
      {
        answer: "sure",
        emotion: {
          document: {
            emotion: { sadness: 0, joy: 0, fear: 0, disgust: 0, anger: 0 },
          },
        },
        sentiment: { document: { score: 0.614077, label: "positive" } },
        length: 1,
        wordsPerMinute: 22.497187851518557,
      },
    ],
  },
  conversation: [
    {
      question: "doubt",
      answer: { text: "sure thing", answerTime: 2679 },
      wordCount: 2,
    },
    {
      question: "doubt",
      answer: { text: "I got nothing", answerTime: 2172 },
      wordCount: 3,
    },
    {
      question: "doubt",
      answer: { text: "not much really know", answerTime: 2913 },
      wordCount: 4,
    },
    {
      question: "regret",
      answer: { text: "much nothing", answerTime: 2190 },
      wordCount: 2,
    },
    {
      question: "future",
      answer: { text: "sure", answerTime: 2578 },
      wordCount: 1,
    },
    {
      question: "regret",
      answer: { text: "no I can't tell you much", answerTime: 3415 },
      wordCount: 6,
    },
    {
      question: "future",
      answer: { text: "okay", answerTime: 2807 },
      wordCount: 1,
    },
    {
      question: "regret",
      answer: { text: "okay sounds good", answerTime: 3341 },
      wordCount: 3,
    },
    {
      question: "future",
      answer: { text: "sure", answerTime: 2667 },
      wordCount: 1,
    },
  ],
};

const fillers = [
  "Great! Now try this one:",
  "Okay, here's another word.. what does it make you think?",
  "Interesting. This is the next word, go again:",
  "One last cue! Here it is:",
];

const nextFillers = [
  "Great, now remember I gave you this suggestion before, what's another thing that comes to mind? :",
  "Now give me something different for this cue:",
  "How about this one, can you think of something different this time?",
  "Good job, now tell me a different idea for this word:",
  "Last one! Any new ideas for this cue?",
];

const repeatFillers = [
  "Can you try saying the same thing, but in a different way?",
  "Great! Now try to say something very similar, but make it different!",
  "Can you try getting the same meaning across again? But make it as different as possible!",
  "Say that one again, perhaps make it more complex and detailed!",
  "Great! Now one more time, give me the same meaning, but perhaps try to simplify it!",
];

// feedback page... similar to interview ? - sentiment analysis + similarity like repeat..

function SayAgainPage() {
  const [isStarted, setStartGame] = useState(false);

  //phase 1 or 2?
  const [animation, setAnimation] = useState("listen");
  const [gameResult, setGameResult] = useState();

  const [currentDialogue, setCurrentDialogue] = useState();
  const [dialogueQueue, setDialogueQueue] = useState([]);
  const [listening, setListening] = useState(false);
  const [speaking, setSpeaking] = useState();

  // set all prompts at beginning
  const [prompts, setPrompts] = useState([]);
  // cache already used prompts
  const [promptCache, setPromptCache] = useState([]);
  const [currentPrompt, setCurrentPrompt] = useState();
  // record all answers { cue: [answers...], ...  }
  const [answers, setAnswers] = useState({});
  const repeatFlag = useRef(false);
  const repeatCount = useRef(0);

  const [initialTime, setInitialTime] = useState();
  const [answerTime, setAnswerTime] = useState();

  // set the next dialogue to be spoken from the dialogue queue
  useEffect(() => {
    async function shiftDialogueQueue(nextLine) {
      await timeout(500);
      setCurrentDialogue(nextLine.words);
      setDialogueQueue([...dialogueQueue]);
    }
    if (dialogueQueue.length && !currentDialogue && !speaking) {
      const nextLine = dialogueQueue.shift();
      shiftDialogueQueue(nextLine);
    }
  });

  useEffect(() => {
    if (speaking) setAnimation("talk");
    else setAnimation("listen");
  }, [speaking]);

  function addToDialogueQueue(statement) {
    dialogueQueue.push(statement);
    setDialogueQueue(dialogueQueue);
  }

  function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async function onBotSpeakingEnd() {
    const nextDialogue = dialogueQueue.length;
    if (nextDialogue) {
      setCurrentDialogue(false);
    } else if (!dialogueQueue.length) {
      setListeningTimed(true);
    }
  }

  function getUpdatedAnswers(words) {
    const updateAnswers = answers;
    const timeElapsed = Date.now() - answerTime;
    const newVal = { text: words, answerTime: timeElapsed };
    if (updateAnswers[currentPrompt]) {
      updateAnswers[currentPrompt].push(newVal);
    } else {
      updateAnswers[currentPrompt] = [newVal];
    }
    return updateAnswers;
  }

  async function exchangeDialogue(words, audioBlob) {
    // first go through all prompts, caching used prompts
    if (!gameResult) {
      if (!prompts.length) {
        // then go through cache
        if (promptCache.length || repeatFlag.current) {
          // repeating each prompt twice
          if (currentPrompt && repeatFlag.current) {
            const fillerSentence = repeatFillers[repeatCount.current];
            addToDialogueQueue({
              words: fillerSentence + " " + currentPrompt,
            });
            repeatFlag.current = false;
            repeatCount.current = repeatCount.current + 1;
          } else {
            const currentPrompts = [...promptCache];
            const nextPrompt = currentPrompts.shift();
            const fillerSentence = nextFillers[repeatCount.current];
            addToDialogueQueue({ words: fillerSentence + " " + nextPrompt });
            setPromptCache(currentPrompts);
            setCurrentPrompt(nextPrompt);
            repeatFlag.current = true;
          }
          // once done with cache, get game results
        } else {
          const updateAnswers = getUpdatedAnswers(words);
          const feedback = await getFeedback(updateAnswers);
          addToDialogueQueue({
            words: "Good job! Game Over! Now let me get you some feedback.",
          });
          console.log(feedback);
          setGameResult(feedback);
        }
      } else {
        const currentPrompts = [...prompts];
        const nextPrompt = currentPrompts.shift();
        const fillerSentence = fillers[promptCache.length - 1];
        addToDialogueQueue({ words: fillerSentence + " " + nextPrompt });
        setPrompts(currentPrompts);
        setPromptCache([...promptCache, nextPrompt]);
        setCurrentPrompt(nextPrompt);
      }

      const updateAnswers = getUpdatedAnswers(words);

      setAnswers(updateAnswers);
      setCurrentDialogue(false);
    }
  }

  async function initiate() {
    // wait for animation to complete
    const gameData = await initiateGame();
    const firstCue = gameData.prompts.shift();
    setStartGame(true);
    setInitialTime(Date.now());
    addToDialogueQueue({
      words:
        "I'm going to give you some cues, I want you to say whatever comes to mind. Here's your first prompt beside me. Just say anything related!",
    });
    // addToDialogueQueue({
    //   words: firstCue,
    // });
    setPrompts(gameData.prompts);

    await timeout(2000);

    setPromptCache([firstCue]);
    setCurrentPrompt(firstCue);
  }

  const restartTheGame = () => {
    setListening(false);
    setCurrentDialogue(false);
    setStartGame(false);
    setGameResult(false);
    setAnimation("listen");
    setPrompts([]);
    setPromptCache([]);
    setAnswers({});
    setCurrentPrompt(null);
    repeatFlag.current = false;
  };

  function handleMicClick() {
    if (!isStarted) {
      initiate();
    } else {
      restartTheGame();
    }
  }

  function setListeningTimed(toggle) {
    if (toggle && !gameResult) {
      setAnswerTime(Date.now());
      setListening(true);
    } else {
      setListening(false);
    }
  }

  return (
    <div className="sayagain full-screen-container">
      <div
        className={
          !isStarted ? "avatar-container-before" : "avatar-container-after"
        }
      >
        <Avatar className="avatar-image" mood={animation} />
        {!isStarted ? (
          <div className="mic-container-before">
            <StartEndButton
              handleMicClick={handleMicClick}
              buttonText={"Start"}
            />
          </div>
        ) : null}
      </div>

      {isStarted && currentPrompt ? (
        <div className="current-prompt">
          <Paper elevation={3}>
            <div className="current-prompt-text">
              <OrangeTextTypography variant="h1" component="h2" gutterBottom>
                {currentPrompt}
              </OrangeTextTypography>
            </div>
          </Paper>
        </div>
      ) : null}

      {isStarted && Object.keys(answers).length ? (
        <div className="past-prompts">
          <Paper elevation={3}>
            <div className="prompt-list">
              <ul>
                {Object.keys(answers).map((prompt, i) => {
                  if (prompt === currentPrompt) {
                    return (
                      <li key={i}>
                        <OrangeTextTypography variant="h5" gutterBottom>
                          {(i + 1).toString() + ". " + prompt}
                        </OrangeTextTypography>
                      </li>
                    );
                  } else
                    return (
                      <li key={i}>
                        <Typography variant="h5" gutterBottom>
                          {(i + 1).toString() + ". " + prompt}
                        </Typography>
                      </li>
                    );
                })}
              </ul>
            </div>
          </Paper>
        </div>
      ) : null}

      {isStarted ? (
        <div className="mic-container-after">
          <Dictaphone
            listening={listening}
            setListening={setListeningTimed}
            speaking={speaking}
            setSpeaking={setSpeaking}
            currentDialogue={currentDialogue}
            exchangeDialogue={exchangeDialogue}
            onEnd={onBotSpeakingEnd}
            handleMicClick={handleMicClick}
            showLevels={isStarted}
            record
            voice="Google US English"
          />
        </div>
      ) : null}

      {isStarted ? (
        <div className="cue-container">
          {promptCache[promptCache.length - 1]}
        </div>
      ) : null}

      {isStarted && gameResult ? (
        <div>
          <SayAgainResults
            closeFunction={restartTheGame}
            feedback={gameResult}
            initialTime={initialTime}
          />
        </div>
      ) : null}

      {/* <ResultDialog
        title="All done! Good job!"
        contentArray={gameResult}
        open={gameResult}
        closeFunction={restartTheGame}
        game="sayagain"
      /> */}
    </div>
  );
}

export default SayAgainPage;
