import React, { useState, useEffect, useRef } from "react";

export default function SpeechRecognition({
  continuous,
  finalTranscript,
  setFinalTranscript,
  setInterimTranscript,
  listening,
}) {
  const BrowserSpeechRecognition =
    typeof window !== "undefined" &&
    (window.SpeechRecognition ||
      window.webkitSpeechRecognition ||
      window.mozSpeechRecognition ||
      window.msSpeechRecognition ||
      window.oSpeechRecognition);
  const recognition = BrowserSpeechRecognition
    ? new BrowserSpeechRecognition()
    : null;
  recognition.continuous = true;
  const browserSupportsSpeechRecognition = recognition !== null;
  const isMounted = useRef(true);

  const [interimTranscript, setInterimTranscriptInternal] = useState("");

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const startListening = () => {
      if (recognition) {
        try {
          recognition.abort();
          recognition.start();
        } catch (DOMException) {
          // Tried to start recognition after it has already started - safe to swallow this error
        }
      }
    };
    const stopListening = () => {
      recognition.stop();
    };
    if (listening) startListening();
    else {
      stopListening();
    }
  }, [listening]);

  const updateTranscript = (event) => {
    let updatedFinal = finalTranscript;
    for (let i = event.resultIndex; i < event.results.length; ++i) {
      if (event.results[i].isFinal) {
        updatedFinal = concatTranscripts(
          updatedFinal,
          event.results[i][0].transcript
        );
        setFinalTranscript(updatedFinal);
      } else if (isMounted.current && listening) {
        setInterimTranscript(event.results[0][0].transcript);
        setInterimTranscriptInternal(event.results[0][0].transcript);
      }
    }
  };

  const concatTranscripts = (...transcriptParts) => {
    return transcriptParts
      .map((t) => t.trim())
      .join(" ")
      .trim();
  };

  var lastStartedAt = 0;

  const startListening = () => {
    if (recognition) {
      try {
        recognition.start();
        lastStartedAt = new Date().getTime();
      } catch (DOMException) {
        // Tried to start recognition after it has already started - safe to swallow this error
      }
    }
  };

  const restart = () => {
    if (
      !finalTranscript.length &&
      !interimTranscript.length &&
      listening &&
      isMounted.current
    ) {
      // play nicely with the browser, and never restart automatically more than once per second
      var timeSinceLastStart = new Date().getTime() - lastStartedAt;
      if (timeSinceLastStart < 1000) {
        setTimeout(function () {
          startListening();
        }, 1000 - timeSinceLastStart);
      } else {
        startListening();
      }
    }
  };

  if (browserSupportsSpeechRecognition) {
    recognition.continuous = true;
    recognition.interimResults = true;
    recognition.onresult = updateTranscript;
    recognition.onend = restart;
  }

  return null;
}
