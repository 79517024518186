import React, { useState, useEffect, useRef } from "react";
import { Star } from "@material-ui/icons";
import ResultDialog from "../components/ResultDialog";
import Dictaphone from "../components/Dictaphone";
import Avatar from "../components/Avatar";
import SkipButton from "../assets/svgs/SkipButton";
import TryAgainButton from "../assets/svgs/TryAgainButton";
import Timer from "../components/Timer";
import {
  getGameResult,
  initiateGame,
  sendRepeatStatement,
  skipStatement,
} from "../services/repeatService";
import StartEndButton from "../components/StartEndButton";
import Conversation from "../components/ConversationLog";

const timeLimit = 10;
const maxWrongAnswers = 2;
const tutorial =
  "Listen carefully to what I say, and try to repeat it as close as possible!";

function RepeatPage() {
  const [conversation, setConversation] = useState([]);
  const [interimTranscript, setInterimState] = useState("");
  const [convoId, setId] = useState(false);
  const [animation, setAnimation] = useState("listen");

  const [isStarted, setStartGame] = useState(false);
  const [isEnded, setEndGame] = useState(false);
  const [gameResult, setGameResult] = useState([]);

  const [score, setScore] = useState(0);
  const [wrongCount, setWrongCount] = useState(0);
  const [currentDialogue, setCurrentDialogue] = useState();
  const [dialogueQueue, setDialogueQueue] = useState([]);
  const [listening, setListening] = useState(false);
  const [speaking, setSpeaking] = useState();

  // set the next dialogue to be spoken from the dialogue queue
  useEffect(() => {
    async function shiftDialogueQueue(nextLine) {
      await timeout(500);
      setCurrentDialogue(nextLine.words);
      setDialogueQueue([...dialogueQueue]);
      addToConversation({
        role: "ai",
        words: nextLine.words,
        hidden: nextLine.hidden,
      });
    }
    if (dialogueQueue.length && !currentDialogue && !speaking) {
      const nextLine = dialogueQueue.shift();
      shiftDialogueQueue(nextLine);
    }
  });

  useEffect(() => {
    if (speaking) setAnimation("talk");
    else setAnimation("listen");
  }, [speaking]);

  // end game hook, displays game result window
  useEffect(() => {
    const endGame = async () => {
      setListening(false);
      const gameSummary = await getGameResult(convoId);
      setGameResult(gameSummary);
    };
    if (isEnded) endGame();
  }, [isEnded]);

  function addToDialogueQueue(statement) {
    dialogueQueue.push(statement);
    setDialogueQueue(dialogueQueue);
  }

  function addToConversation(statement) {
    conversation.push(statement);
    setConversation([...conversation]);
  }

  function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async function onBotSpeakingEnd() {
    const nextDialogue = dialogueQueue.length;
    if (nextDialogue) {
      setCurrentDialogue(false);
    } else {
      setInterimState("");
      if (!dialogueQueue.length && wrongCount < maxWrongAnswers)
        setListening(true);
    }
  }

  async function exchangeDialogue(words, audioBlob) {
    addToConversation({
      role: "human",
      words: words,
      audioBlob: audioBlob,
      hidden: false,
    });
    setInterimState("");

    // send utterance for analysis, receive feedback or new statement to repeat
    const response = await sendRepeatStatement(words, convoId);

    // handle wrong answer
    var updateWrongCount = wrongCount;
    if (response.feedback) {
      updateWrongCount += 1;
      if (updateWrongCount < maxWrongAnswers)
        addToDialogueQueue({ words: response.feedback });
    } else if (wrongCount) updateWrongCount = 0;
    setWrongCount(updateWrongCount);
    // if wrong twice, disable turn (for option to skip)
    if (updateWrongCount < maxWrongAnswers) {
      addToDialogueQueue({ words: response.nextEntry, hidden: true });
      if (response.score > score) setScore(response.score);
    } else {
      setDialogueQueue([{ words: "Would you like to skip this one?" }]);
      setListening(false);
    }
    setCurrentDialogue(false);
  }

  async function handleSkip() {
    const newStatement = await skipStatement(convoId);
    addToDialogueQueue({ words: newStatement, hidden: true });
    setWrongCount(0);
    setListening(false);
    setInterimState("");
    setCurrentDialogue(false);
  }

  async function handleTryAgain() {
    setWrongCount(0);
    addToDialogueQueue({
      words: conversation[conversation.length - 3].words,
      hidden: true,
    });
    setListening(false);
    setInterimState("");
    setCurrentDialogue(false);
  }

  // initiate new game session
  async function initiate() {
    setStartGame(true);
    // wait for animation to complete
    await timeout(2000);
    addToDialogueQueue({ words: tutorial });
    const gameData = await initiateGame();
    setId(gameData.convoId);
    addToDialogueQueue({ words: gameData.words, hidden: true });
  }

  const restartTheGame = () => {
    setListening(false);
    setConversation([]);
    setCurrentDialogue(false);
    setScore(0);
    setId(false);
    setStartGame(false);
    setGameResult([]);
    setWrongCount(0);
    setEndGame(false);
    setAnimation("listen");
  };

  function handleMicClick() {
    if (!isStarted) {
      initiate();
    } else {
      restartTheGame();
    }
  }

  return (
    <div className="repeat chat full-screen-container">
      <div
        className={
          !isStarted ? "avatar-container-before" : "avatar-container-after"
        }
      >
        <Avatar className="avatar-image" mood={animation} />
        {!isStarted ? (
          <div className="mic-container-before">
            <StartEndButton
              handleMicClick={handleMicClick}
              buttonText={"Start"}
            />
          </div>
        ) : null}
      </div>

      <div className="conversation-container">
        <Conversation
          conversation={conversation}
          interimTranscript={interimTranscript}
          isPlayerTurn={listening}
        />
      </div>

      {isStarted ? (
        <div className="mic-container-after">
          <Dictaphone
            listening={listening}
            setListening={setListening}
            speaking={speaking}
            setSpeaking={setSpeaking}
            setInterimState={setInterimState}
            currentDialogue={currentDialogue}
            exchangeDialogue={exchangeDialogue}
            onEnd={onBotSpeakingEnd}
            handleMicClick={handleMicClick}
            showLevels={isStarted}
            record
          />
          {wrongCount >= maxWrongAnswers ? (
            <div className="skip-button-container">
              <SkipButton onClick={() => handleSkip()} />
              <TryAgainButton onClick={() => handleTryAgain()} />
            </div>
          ) : null}
        </div>
      ) : null}

      <ResultDialog
        title="Your time is up!"
        contentArray={gameResult}
        open={isEnded}
        closeFunction={restartTheGame}
        conversation={conversation}
        maxWidth="false"
      />
      {isStarted ? (
        <div className="timer-container">
          <Timer
            timeLimit={timeLimit}
            active={listening}
            setEndGame={setEndGame}
            className="timer"
          />
          <div className={`rating-box `}>
            <Star className="star-icon" />
            <span>{score}</span>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default RepeatPage;
