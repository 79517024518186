import React from "react";

const MicSvg = ({ isActive }) => {
  return (
    <svg
      width="86"
      height="86"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="43" cy="43" r="43" fill="white" />
      <path
        d="M57.8832 36.4569C57.8832 33.9623 61.6967 33.9623 61.6967 36.4569V39.3352C61.6967 49.3136 54.2603 57.5649 44.5358 58.5243V65.4324C44.5358 67.927 40.7222 67.927 40.7222 65.4324V58.5243C30.9977 57.5649 23.3706 49.3136 23.3706 39.3352V36.4569C23.3706 33.9623 27.1841 33.9623 27.1841 36.4569V39.3352C27.1841 47.7784 34.0485 54.8784 42.629 54.8784C51.0188 54.8784 57.8832 47.7784 57.8832 39.3352V36.4569Z"
        fill={isActive ? "#ff4a4a" : "#FFBA8D"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43 19.6299C38.411 19.6299 34.5869 23.369 34.5869 27.856V39.4473C34.5869 43.9342 38.411 47.6734 43 47.6734C47.5889 47.6734 51.413 43.9342 51.413 39.4473V27.856C51.413 23.369 47.5889 19.6299 43 19.6299Z"
        fill={isActive ? "#ff4a4a" : "#FFBA8D"}
      />
    </svg>
  );
};

export default MicSvg;
