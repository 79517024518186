import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import "../App.css";

import { ExportConversation } from "./ExportConversation";
import GoodJobSvg from "./GoodJobSvg";
import MissingSentences from "./MissingSentences";
import PerfectSentences from "./PerfectSentences";
import CloseButtonSvg from "./CloseButtonSvg";

const ResultDialog = ({
  open,
  closeFunction,
  title,
  contentArray,
  conversation,
  maxWidth,
  handleClose,
  game,
}) => {
  const [selected, setSelected] = React.useState(0);

  const handleChange = (event, newSelected) => {
    setSelected(newSelected);
  };
  if (game === "sayagain") {
    return (
      <div className="info-dialog">
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth={false}
          maxWidth={maxWidth}
          PaperProps={{
            style: { borderRadius: 50 },
          }}
          className="dialog"
        >
          <div className="full-container">
            <div className="left-graphic">
              <div
                style={{
                  paddingRight: "25px",
                  paddingTop: "10px",
                  float: "right",
                }}
                onClick={closeFunction}
              >
                <CloseButtonSvg />
              </div>
              <div className="robot-container">
                <GoodJobSvg />
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
  return (
    <div className="info-dialog">
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={false}
        maxWidth={maxWidth}
        PaperProps={{
          style: { borderRadius: 50 },
        }}
        className="dialog"
      >
        <div className="full-container">
          <div className="left-graphic">
            <div className="robot-container">
              <GoodJobSvg />
            </div>

            <h1 style={{ marginTop: "-1em" }}>Score: 30</h1>
            <h2>Average Similarity: 80%</h2>
            <DialogActions className="dialog-actions">
              <ExportConversation conversation={conversation} />
            </DialogActions>
          </div>

          <div className="right-sentences">
            <div className="close-container" onClick={closeFunction}>
              <CloseButtonSvg />
            </div>
            <Tabs
              className="tab-selector"
              TabIndicatorProps={{
                style: { background: "#5264E5", height: "0.2em" },
              }}
              value={selected}
              onChange={handleChange}
            >
              <Tab
                style={
                  selected === 0
                    ? {
                        color: "#5264E5",
                        textTransform: "none",
                        fontSize: "1.3em",
                        fontWeight: 700,
                      }
                    : {
                        color: "#A0A0A0",
                        textTransform: "none",
                        fontSize: "1.3em",
                        fontWeight: 700,
                      }
                }
                label="Missing Sentences"
              />
              <Tab
                style={
                  selected === 1
                    ? {
                        color: "#5264E5",
                        textTransform: "none",
                        fontSize: "1.3em",
                        fontWeight: 700,
                      }
                    : {
                        color: "#A0A0A0",
                        textTransform: "none",
                        fontSize: "1.3em",
                        fontWeight: 700,
                      }
                }
                label="Perfect Sentences"
              />
            </Tabs>
            {selected === 0 && contentArray.diffs ? (
              <MissingSentences
                diffs={contentArray.diffs.filter((diff) => {
                  return diff.similarity < 0.95;
                })}
              />
            ) : null}
            {selected === 1 && (
              <PerfectSentences
                diffs={contentArray.diffs.filter((diff) => {
                  return diff.similarity >= 0.95;
                })}
              />
            )}
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default ResultDialog;
