import { useDropzone } from "react-dropzone";
import React, { useCallback } from "react";
import Typography from "@material-ui/core/Typography";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

export default function FileDrop({ setConversation }) {
  const readUploadedFileAsText = (inputFile) => {
    const temporaryFileReader = new FileReader();

    return new Promise((resolve, reject) => {
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        reject(new DOMException("Problem parsing input file."));
      };

      temporaryFileReader.onload = () => {
        resolve(temporaryFileReader.result);
      };
      temporaryFileReader.readAsText(inputFile);
    });
  };

  const base64ToAudioBlob = (dataURI) => {
    let BASE64_MARKER = ";base64,";
    let base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    let base64 = dataURI.substring(base64Index);
    let raw = window.atob(base64);
    let rawLength = raw.length;
    let arr = new Uint8Array(new ArrayBuffer(rawLength));

    for (let i = 0; i < rawLength; i++) {
      arr[i] = raw.charCodeAt(i);
    }

    let blob = new Blob([arr], {
      type: "audio/mp4",
    });
    let blobUrl = URL.createObjectURL(blob);
    return blobUrl;
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      let conversation = [];
      acceptedFiles.forEach(async (file) => {
        const fileToText = await readUploadedFileAsText(file);
        JSON.parse(fileToText).forEach((lineOfDialogue) => {
          if (lineOfDialogue.audioBlob)
            lineOfDialogue.audioBlob = base64ToAudioBlob(
              lineOfDialogue.audioBlob
            );
          conversation.push(lineOfDialogue);
        });
        setConversation(conversation);
        console.log("convoset");
      });
    },
    [setConversation]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className="PlayBack-input" {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <p className="text-bold">Drop the files here ...</p>
      ) : (
        <div>
          <CloudUploadIcon className="upload-btn"></CloudUploadIcon>
          <Typography gutterBottom className="text-bold">
            Drag and Drop your file here
          </Typography>
          <Typography gutterBottom className="text-bold">
            or
          </Typography>
          <Typography className="text-pink text-bold">Browse files</Typography>
        </div>
      )}
    </div>
  );
}
