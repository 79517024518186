import React from "react";

export default function AudioLevelDisplay({ listening, level }) {
  return (
    <div className="audio-display-container">
      <div
        className={
          listening && level > 5 ? "audio-display-on" : "audio-display-off"
        }
      ></div>
      <div
        className={
          listening && level > 10 ? "audio-display-on" : "audio-display-off"
        }
      ></div>
      <div
        className={
          listening && level > 15 ? "audio-display-on" : "audio-display-off"
        }
      ></div>
      <div
        className={
          listening && level > 30 ? "audio-display-on" : "audio-display-off"
        }
      ></div>
      <div
        className={
          listening && level > 60 ? "audio-display-on" : "audio-display-off"
        }
      ></div>
    </div>
  );
}
