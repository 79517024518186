import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { createMuiTheme } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import VolumeSvg from "../components/VolumeSvg";
import SpeechSynthesis from "../utils/SpeechSynthesis";
import "../App.css";

const botSpeech = (content) => {
  const synthesis = new SpeechSynthesis({
    voice: "Google UK English Female",
  });
  synthesis.speak(content);
};

// TODO - retrieve current dialogue from user
const userSpeech = (content) => {
  // const audio = new Audio(currentDialogue.audioBlob);
  // audio.play();
};

const MissingSentences = ({ diffs }) => {
  var index = 1;
  var theme = createMuiTheme();

  return (
    <div>
      {diffs.map((f) => {
        var sentence = [];
        for (var i = 0; i < f.diff.length; i++) {
          var text;

          if (f.diff[i].removed) {
            text = (
              <span className="removed-text" key={i}>
                {f.diff[i].value} <span> </span>
              </span>
            );
          } else if (f.diff[i].added) {
            text = (
              <span className="added-text">
                {f.diff[i].value} <span> </span>
              </span>
            );
          } else {
            text = (
              <span className="reg-text">
                {f.diff[i].value} <span> </span>
              </span>
            );
          }

          sentence[i] = text;
        }
        return (
          <Card className="card">
            <CardContent>
              <div className="card-container">
                <Avatar
                  style={{
                    color: "#FFFFFF",
                    backgroundColor: "#5264E5",
                    width: theme.spacing(2),
                    height: theme.spacing(2),
                    fontSize: "10px",
                  }}
                >
                  {index++}
                </Avatar>
                <div className="card-top">
                  <div className="card-title">
                    <h3>Standard</h3>
                    <div onClick={() => botSpeech(f.before)}>
                      <VolumeSvg />
                    </div>
                  </div>
                  <span className="standard-text">{f.before}</span>

                  <div className="card-title">
                    <h3>Mine</h3>
                    <VolumeSvg />
                  </div>
                  {sentence}
                </div>
              </div>
            </CardContent>
          </Card>
        );
      })}
    </div>
  );
};

export default MissingSentences;
