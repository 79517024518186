import React from 'react'

const VolumeSvg = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.8685 3.07693C11.9999 3.14042 12.1108 3.23978 12.1884 3.36362C12.2661 3.48745 12.3073 3.63074 12.3075 3.77703V16.2232C12.3073 16.3696 12.2659 16.5129 12.1881 16.6368C12.1103 16.7606 11.9992 16.8599 11.8677 16.9233C11.7361 16.9867 11.5894 17.0115 11.4444 16.995C11.2993 16.9785 11.1619 16.9213 11.0479 16.83L7.38227 13.8896H3.77562C3.56991 13.8896 3.37263 13.8076 3.22717 13.6617C3.08172 13.5158 3 13.318 3 13.1117V6.88858C3 6.68227 3.08172 6.48441 3.22717 6.33853C3.37263 6.19264 3.56991 6.11069 3.77562 6.11069H7.38227L11.0479 3.17028C11.162 3.07878 11.2996 3.02147 11.4447 3.00496C11.5899 2.98844 11.7368 3.01339 11.8685 3.07693ZM10.7562 5.39503L8.13773 7.49533C8.00043 7.6058 7.82975 7.66615 7.65374 7.66646H4.55125V12.3338H7.65374C7.82975 12.3341 8.00043 12.3944 8.13773 12.5049L10.7562 14.6052V5.39503Z" fill="#5264E5"/>
    <path d="M14.9561 14.9498C15.6053 14.3004 16.1201 13.5287 16.471 12.6792C16.8218 11.8296 17.0018 10.9189 17.0006 9.9993C17.0018 9.07974 16.8218 8.16901 16.471 7.31944C16.1201 6.46987 15.6053 5.69822 14.9561 5.04883L13.8594 6.14876C14.3643 6.65387 14.7647 7.25408 15.0375 7.91489C15.3104 8.57569 15.4504 9.28407 15.4494 9.9993C15.4494 11.5022 14.8413 12.8635 13.8594 13.8498L14.9561 14.9498Z" fill="#5264E5"/>
    </svg>
  )
}

export default VolumeSvg
