import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import BotFeedbackImage from "../assets/svgs/interview/BotFeedbackImage.jsx";
// import InterviewResultBackground from "../assets/svgs/interview/InterviewResultBackground.jsx";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import "../App.css";

import GoodJobSvg from "./GoodJobSvg";
import MissingSentences from "./MissingSentences";
import PerfectSentences from "./PerfectSentences";
import CloseButtonSvg from "./CloseButtonSvg";
import { ExportConversation } from "./ExportConversation";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import _ from "lodash";

const InterviewResult = ({
  closeFunction,
  conversation,
  setDownloading,
  feedback,
}) => {
  const [selectedTab, setSelectedTab] = useState("summary");
  const [expandedAnswer, setExpandedAnswer] = useState();

  const emotionCircle = (percentage, emotion) => {
    return (
      <div className="emotion-circle-container">
        <CircularProgressbar
          value={percentage}
          text={
            <tspan
              dy={10}
              dx={
                percentage < -10
                  ? -30
                  : percentage < 0
                  ? -25
                  : percentage < 10
                  ? -15
                  : -25
              }
            >
              {_.round(percentage) + "%"}
            </tspan>
          }
          styles={buildStyles({
            font: "Montserrat",
            fontWeight: "600px",
            textSize: "30px",
            pathColor: `rgba(82, 100, 228, ${percentage / 100})`,
            textColor: "#000000",
            trailColor: "#EAEAEA",
            backgroundColor: "#FFFFFF",
          })}
        />
        <div className="emotion-name">{emotion}</div>
      </div>
    );
  };

  let answerCollapse = (question, index) => {
    return (
      <div className="answer-collapsable">
        <div>
          <div className="collapse-number">{index + 1}</div>
          <div className="collapse-question">{question}</div>
          {expandedAnswer === index + 1 ? (
            <div
              className="collapse-collapse-button"
              onClick={() => {
                setExpandedAnswer(0);
              }}
            />
          ) : (
            <div
              className="collapse-expand-button"
              onClick={() => {
                setExpandedAnswer(index + 1);
              }}
            />
          )}
        </div>
        {expandedAnswer === index + 1 ? (
          <div>
            <div className="review-answer">
              {index + 1 < feedback.conversation.length
                ? feedback.conversation[index + 1].answer
                : null}
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  let getInterviewDuration = () => {
    let duration = (Date.now() - feedback.settings.initialTime) / 60000;
    let seconds = (duration % 1) * 60;
    return (
      Math.floor(duration).toString() + " min " + _.round(seconds) + " sec"
    );
  };

  let getTotalWordCount = () => {
    let sum = 0;
    feedback.conversation.forEach((dialogue) => {
      sum += dialogue.wordCount;
    });
    return sum;
  };

  return (
    <div className="result-container">
      <div className="interview-feedback-title">Interview Complete</div>
      <div className="interview-result-export">
        <ExportConversation
          setDownloading={setDownloading}
          conversation={conversation}
          mode={"interview"}
        />
      </div>
      <div className="interview-tab-buttons">
        <div
          className={
            selectedTab === "summary"
              ? "interview-summary-button tab-selected"
              : "interview-summary-button"
          }
          onClick={() => setSelectedTab("summary")}
        >
          Summary
        </div>
        <div
          className={
            selectedTab === "review"
              ? "interview-review-button tab-selected"
              : "interview-review-button"
          }
          onClick={() => setSelectedTab("review")}
        >
          Review
        </div>
      </div>
      <div
        className="interview-close-button"
        onClick={() => {
          closeFunction();
        }}
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.0362 12L23.5781 2.45837C24.1406 1.89606 24.1406 0.984297 23.5781 0.421979C23.016 -0.14034 22.1038 -0.14034 21.5419 0.421979L12 9.96364L2.45837 0.421739C1.89606 -0.14058 0.984058 -0.14058 0.421979 0.421739C-0.14034 0.984058 -0.14034 1.89582 0.421979 2.45813L9.96364 12L0.421739 21.5419C-0.14058 22.1042 -0.14058 23.016 0.421739 23.5781C0.703018 23.8594 1.07142 24 1.44006 24C1.8087 24 2.17709 23.8594 2.45837 23.5781L12 14.0362L21.5419 23.5781C21.823 23.8594 22.1916 24 22.56 24C22.9284 24 23.297 23.8594 23.5781 23.5781C24.1406 23.0158 24.1406 22.104 23.5781 21.5419L14.0362 12Z"
            fill="#A0A0A0"
          />
        </svg>
      </div>
      {selectedTab === "summary" ? (
        <div className="interview-stats">
          <div className="interview-stat stat1">
            • Time to answer{" "}
            <u>{" " + feedback.conversation.length.toString() + " "}</u>{" "}
            questions: . . . . . . .{getInterviewDuration()}
            <span className="stat-highlight"></span>
          </div>
          <div className="interview-stat stat2">
            • Word count: . . . . . . . . . . . . . . . . . . . . . . . . . . .
            {getTotalWordCount()} <span className="stat-highlight"></span>
          </div>
          <div className="interview-stat stat3">
            • Average words per minute: . . . . . . . . . . . . . . . .
            {_.round(feedback.averageWPM)}
            <span className="stat-highlight"></span>
          </div>
          <div className="stat-summary">
            Based on these results, Skyler suggests speaking{" "}
            <span className="stat-highlight">
              {feedback.averageWPM > 180
                ? "a little bit slower."
                : feedback.averageWPM < 100
                ? "a little bit faster."
                : "at about the same pace."}
            </span>{" "}
            Always remember to speak at a steady pace!
          </div>
          <div className="analysis-title">How does your voice sound?</div>
          <div className="feedback-tooltip" />
          {feedback.analysisSummary ? (
            <div className="emotion-analysis">
              {emotionCircle(
                feedback.analysisSummary.sadnessMean * 100,
                "Sadness"
              )}
              {emotionCircle(feedback.analysisSummary.joyMean * 100, "Joy")}
              {emotionCircle(feedback.analysisSummary.fearMean * 100, "Fear")}
              {emotionCircle(
                feedback.analysisSummary.disgustMean * 100,
                "Disgust"
              )}
              {emotionCircle(feedback.analysisSummary.angerMean * 100, "Anger")}
              {emotionCircle(
                feedback.analysisSummary.sentimentMean * 100,
                "Sentiment"
              )}
            </div>
          ) : null}
        </div>
      ) : (
        <div className="review-page">
          <ul>
            {feedback.conversation.map((entry, i) => {
              if (i < feedback.conversation.length - 1)
                return <li key={i}>{answerCollapse(entry.question, i)}</li>;
              else return null;
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default InterviewResult;
